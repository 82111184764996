import AC from '../../../components/AccessControl';
import { Dropdown } from 'antd';
import { useRouter } from 'next/router';
const MenuIcon = ({
  color,
  url,
  title,
  isCurrent
}) => {
  const [R, G, B] = color.match(/[0-9A-F]{2}/gi).map(hex => parseInt(hex, 16) / 255);
  const id = `toolkit-item-icon-${color.replace('#', '')}`;
  const style = {
    width: '100%',
    height: '100%'
  };
  if (isCurrent) {
    style.backgroundColor = color;
  } else {
    style.filter = `url("#${id}")`;
  }
  return <figure title={title} className="menu-item-icon" data-sentry-component="MenuIcon" data-sentry-source-file="Menu.jsx">
      <svg viewBox="0 0 0 0" width="0" height="0" data-sentry-element="svg" data-sentry-source-file="Menu.jsx">
        <filter id={id} colorInterpolationFilters="sRGB" data-sentry-element="filter" data-sentry-source-file="Menu.jsx">
          <feColorMatrix type="matrix" values={`
              ${R}    0    0 0 0
                 0 ${G}    0 0 0
                 0    0 ${B} 0 0
                 0    0    0 1 0
            `} data-sentry-element="feColorMatrix" data-sentry-source-file="Menu.jsx" />
        </filter>
      </svg>
      <img src={url} alt={title} style={style} />
    </figure>;
};
export const MenuLink = ({
  page: {
    type,
    slug,
    name
  },
  color,
  iconURL
}) => {
  const {
    pathname,
    query
  } = useRouter();
  const href = {
    pathname: `/toolkits/${type}/[slug]`,
    query: {
      slug
    }
  };
  return <AC.Link href={href} label={<MenuIcon color={color} url={iconURL} title={name} isCurrent={pathname === href.pathname && query.slug === slug} />} data-sentry-element="unknown" data-sentry-component="MenuLink" data-sentry-source-file="Menu.jsx" />;
};
export const MenuDropdown = ({
  menu: {
    title,
    pages
  },
  color,
  iconURL
}) => {
  const {
    pathname,
    query
  } = useRouter();
  const items = pages.map(({
    type,
    slug,
    name,
    linkName
  }) => {
    const href = {
      pathname: `/toolkits/${type}/[slug]`,
      query: {
        slug
      }
    };
    return {
      current: pathname === href.pathname && query.slug === slug,
      key: `${type}:${slug}`,
      label: <AC.Link href={href} label={linkName || name} />
    };
  });
  const selectedKeys = items.filter(i => i.current).map(i => i.key);
  return <Dropdown menu={{
    selectedKeys,
    items
  }} trigger={['click']} children={<a><MenuIcon color={color} url={iconURL} title={title} isCurrent={selectedKeys.length > 0} /></a>} data-sentry-element="Dropdown" data-sentry-component="MenuDropdown" data-sentry-source-file="Menu.jsx" />;
};