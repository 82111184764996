import { useEffect, useState } from 'react';
const memory = new Map();
const useHistoryState = (key, initValue) => {
  const [val, setVal] = useState(initValue);
  useEffect(() => {
    const state = window.history.state?.options?.state ?? {};
    state.hasOwnProperty(key) && setVal(state[key]);
  }, []);
  useEffect(() => {
    window.history.replaceState({
      ...window.history.state,
      options: {
        ...window.history.state?.options,
        state: {
          ...window.history.state?.options?.state,
          [key]: val
        }
      }
    }, '');
  });
  useEffect(() => {
    if (memory.has(key) && memory.get(key) !== setVal) {
      throw new Error(`Duplicate key '${key}'`);
    }
    memory.set(key, setVal);
    return () => {
      memory.delete(key);
    };
  }, [key, setVal]);
  return [val, setVal];
};
export default useHistoryState;