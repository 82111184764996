// shouldn't be needed in app router
// https://github.com/vercel/next.js/issues/3303

import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
const ScrollRestore = ({
  children
}) => {
  const router = useRouter();
  const scrollCache = useRef({});
  const activeRestorePath = useRef();
  useEffect(() => {
    history.scrollRestoration = "manual";
    const getCurrentPath = () => location.pathname + location.search;
    router.beforePopState(() => {
      activeRestorePath.current = getCurrentPath();
      return true;
    });
    const onComplete = () => {
      const scrollPath = activeRestorePath.current;
      if (!scrollPath || !(scrollPath in scrollCache.current)) {
        return;
      }
      activeRestorePath.current = undefined;
      const [scrollX, scrollY] = scrollCache.current[scrollPath];
      const delays = [0, 10, 20, 40, 80, 100, 100, 100, 100];
      const checkAndScroll = () => {
        if (scrollPath !== getCurrentPath()) {
          return;
        }
        const docEl = document.documentElement;
        const maxScrollY = docEl.scrollHeight - docEl.clientHeight;
        const maxScrollX = docEl.scrollWidth - docEl.clientWidth;
        if (maxScrollX >= scrollX && maxScrollY >= scrollY) {
          window.scrollTo(scrollX, scrollY);
        } else {
          const delay = delays.shift();
          if (delay) {
            setTimeout(checkAndScroll, delay);
          }
        }
      };
      setTimeout(checkAndScroll, delays.shift());
    };
    const onScroll = () => {
      scrollCache.current[getCurrentPath()] = [window.scrollX, window.scrollY];
    };
    router.events.on("routeChangeComplete", onComplete);
    window.addEventListener("scroll", onScroll);
    return () => {
      router.events.off("routeChangeComplete", onComplete);
      window.removeEventListener("scroll", onScroll);
    };
  }, []);
  return children;
};
export default ScrollRestore;