import { Select } from 'antd';
import { capitalize } from 'lodash';
import { useRouter } from 'next/router';
import ClassAvatar from '../images/ClassAvatar';
const labelFor = c => <>
    {!c.phantomClass && <ClassAvatar of={c} size="1.3em" />}
    {' '}
    {c.name}
    {!c.phantomClass && ` (${[capitalize(c.type), c.semester, c.grade].join(', ')})`}
  </>;
const EnrollmentPicker = ({
  enrollments,
  currentClass
}) => {
  const router = useRouter();
  const {
    pathname,
    query
  } = router;
  const setEnrollment = value => {
    router.push({
      pathname: pathname,
      query: {
        ...query,
        classId: value
      }
    });
  };
  if (!enrollments) {
    return null;
  }
  if (enrollments.length === 1) {
    return labelFor(currentClass);
  }
  return <Select value={currentClass.id} size="small" className="enrollment-dropdown" onSelect={setEnrollment} dropdownMatchSelectWidth={false} data-sentry-element="Select" data-sentry-component="EnrollmentPicker" data-sentry-source-file="EnrollmentPicker.jsx">
      {enrollments.map(c => <Select.Option key={c.id} value={c.id} children={labelFor(c)} />)}
    </Select>;
};
export default EnrollmentPicker;