import dayjs from 'dayjs';
import Model from './Model';
export default class Class extends Model {
  constructor(entityData) {
    if (entityData.__typename !== 'Class') {
      throw new Error('invalid data supplied');
    }
    super(entityData);
  }
  get defaultDateRange() {
    const from = dayjs(this.startDate, 'YYYY-MM-DD');
    const to = dayjs(this.endDate, 'YYYY-MM-DD');
    return this.adjustDateRange({
      from,
      to
    });
  }
  get possibleDateRange() {
    const from = dayjs(this.startDate, 'YYYY-MM-DD');
    const to = dayjs(this.endDate, 'YYYY-MM-DD');
    return {
      from: from.isBefore(super.possibleDateRange.from) ? super.possibleDateRange.from : from,
      to: to.isAfter(super.possibleDateRange.to) ? super.possibleDateRange.to : to
    };
  }
  get quickDateRanges() {
    const ranges = super.quickDateRanges.concat([{
      label: 'From Class Start Date',
      value: {
        from: dayjs(this.startDate, 'YYYY-MM-DD'),
        to: dayjs(this.endDate, 'YYYY-MM-DD')
      }
    }]);
    ranges.forEach(r => r.value = this.adjustDateRange(r.value));
    return ranges;
  }
}