import { Menu } from 'antd';
import { FolderOutlined, LineChartOutlined } from '@ant-design/icons';
import ACLinksMenu from './ACLinksMenu';
export default function CustomMenu({
  updateState,
  setIsOpen,
  location: {
    submenus = [],
    users = []
  }
}) {
  const links = users.map(item => ({
    href: {
      pathname: '/reports/user/overview',
      query: {
        id: item.id
      }
    },
    label: <>
				<LineChartOutlined /> {item.name}
			</>
  }));
  return <>
		{submenus.length > 0 && <Menu onClick={e => updateState(e.item.props.value)} items={submenus.map(sm => ({
      key: `${sm.type}-${sm.id}`,
      value: sm,
      label: <>
							<FolderOutlined /> {sm.name}
						</>
    }))} />}
		{links.length > 0 && <ACLinksMenu onClick={() => setIsOpen(false)} title="" links={links} />}
		</>;
}
;