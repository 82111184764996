import { Col, Layout, Row, Spin } from 'antd';
import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import { mySession } from '../../helpers/gqlQueries/me';
import { SessionProvider } from '../../lib/SessionContext';
import { AnalyticsProvider } from '../../lib/analytics';
import { useQuery } from '../../lib/hooks';
let lastKeepAlive = new Date();
const keepSessionAlive = () => {
  if (new Date() - lastKeepAlive > 60000) {
    fetch(process.env.keepSessionAliveURL, {
      credentials: 'include',
      mode: 'no-cors'
    });
    lastKeepAlive = new Date();
  }
};
const eventHandler = ['click', keepSessionAlive, {
  passive: true
}];
const SessionController = ({
  children
}) => {
  const {
    loading,
    error,
    data
  } = useQuery(mySession);
  const state = !loading && !error && data?.me || {};
  useEffect(() => {
    if (state.user) {
      document.addEventListener(...eventHandler);
      Sentry.setUser({
        id: state.user.id,
        username: state.user.name
      });
      Sentry.setTag('user.type', state.user.type);
      return () => {
        document.removeEventListener(...eventHandler);
        Sentry.setUser(null);
        Sentry.setTag('user.type', null);
      };
    }
  }, [state]);
  if (!state.user) {
    return <Layout style={{
      height: '100vh'
    }}>
				<Layout.Content style={{
        alignItems: 'center'
      }}>
					<Row type="flex" justify="center" align="middle" style={{
          height: '100%'
        }}>
						<Col span={8} style={{
            textAlign: 'center'
          }}>
							<h1>Logging in...</h1>
							<Spin size="large" />
						</Col>
					</Row>
				</Layout.Content>
			</Layout>;
  }
  return <SessionProvider value={Object.seal(state)} data-sentry-element="SessionProvider" data-sentry-component="SessionController" data-sentry-source-file="SessionController.jsx">
			<AnalyticsProvider data-sentry-element="AnalyticsProvider" data-sentry-source-file="SessionController.jsx">
				<main children={children} />
			</AnalyticsProvider>
		</SessionProvider>;
};
export default SessionController;