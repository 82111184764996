import { Badge, Button, List, Modal, Tabs, Tag } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useState } from 'react';
import cmsFactory from '../components/cmsFactory';
import { formatDatetime } from '../helpers/datetime';
import htmlStringToReactNodes from '../helpers/htmlStringToReactNodes';
import { useLocalStorageState } from '../lib/hooks';
import styles from './ReleaseNotes.module.scss';
const ReleaseNotes = ({
  data: {
    title,
    releaseNotesList
  }
}) => {
  const [modalState, setModalState] = useState(false);
  const [lastReadDate, setLastReadDate] = useLocalStorageState('realease-notes-last-read-date', dayjs(0).format('YYYY-MM-DD'));
  const openModal = () => {
    setModalState(true);
    setLastReadDate(dayjs().format('YYYY-MM-DD'));
  };
  const somethingNew = releaseNotesList.some(({
    attributes: {
      releaseDate
    }
  }) => dayjs(releaseDate).isAfter(lastReadDate));
  const tabItems = [{
    label: "What's New",
    key: 'release notes',
    children: <List className={styles["release-notes-list"]} itemLayout="vertical" size="large" pagination={{
      pageSize: 3,
      position: 'top',
      size: 'small'
    }} dataSource={releaseNotesList} renderItem={({
      attributes: {
        notes,
        title,
        releaseNumber,
        releaseDate,
        components
      }
    }) => <List.Item key={releaseNumber}>
                <List.Item.Meta title={title} />
                <div children={htmlStringToReactNodes(notes)} />
                <br />
                <div>
                  <em>Release Date: {formatDatetime(releaseDate)} </em>
                  <br />
                  {releaseNumber ? <em>Version {releaseNumber}</em> : null}
                </div>
                {components.length > 0 && <div className={styles["components-list"]} children={components.map(tag => <Tag key={tag} size="small" color="geekblue" children={tag} />)} />}
              </List.Item>} />
  }];
  return <>
      <Button type="link" shape="circle" size="large" onClick={openModal} icon={<Badge dot styles={{
      root: {
        color: 'inherit'
      }
    }} count={somethingNew ? 1 : 0} title="" children={<BellOutlined className={somethingNew ? styles.animated : null} size="large" />} />} data-sentry-element="Button" data-sentry-source-file="ReleaseNotes.jsx" />
      <Modal title={<h3 children={title} />} centered styles={{
      body: {
        height: '80vh'
      }
    }} open={modalState} footer={null} onCancel={() => setModalState(false)} width={960} children={<Tabs className={styles.tabs} tabPosition="left" items={tabItems} />} data-sentry-element="Modal" data-sentry-source-file="ReleaseNotes.jsx" />
    </>;
};
export default cmsFactory(ReleaseNotes, '/content/singletons/releaseNotesList.json');