import dayjs from 'dayjs';
import Model from './Model';
export default class User extends Model {
  constructor(entityData, {
    classID,
    semesters
  }) {
    if (entityData.__typename !== 'User') {
      throw new Error('invalid data supplied');
    }
    super(entityData);
    this.__class = this.enrollments?.find(e => e.class.id === classID);
    if (this.__class) {
      this.__class = {
        startDate: this.__class.from,
        endDate: this.__class.to
      };
    }
    this.__semester = semesters.reduce((min, v) => min.order < v.order ? min : v, {});
  }
  get defaultDateRange() {
    let from = dayjs().subtract(31, 'days');
    let to = dayjs().subtract(1, 'days');
    if (this.type === 'STUDENT') {
      if (this.__class) {
        from = dayjs(this.__class.startDate, 'YYYY-MM-DD');
        to = dayjs(this.__class.endDate, 'YYYY-MM-DD');
      } else {
        from = dayjs(this.earliestInteraction ?? this.creationDate, 'YYYY-MM-DD');
      }
    }
    return this.adjustDateRange({
      from,
      to
    });
  }
  get possibleDateRange() {
    let from = dayjs().subtract(1, 'days').subtract(2, 'years');
    let to = dayjs().subtract(1, 'days');
    if (this.type === 'STUDENT') {
      if (this.__class) {
        from = dayjs(this.__class.startDate, 'YYYY-MM-DD');
        to = dayjs(this.__class.endDate, 'YYYY-MM-DD');
      } else {
        from = dayjs(this.earliestInteraction ?? this.creationDate, 'YYYY-MM-DD');
      }
    }
    return {
      from: from.isBefore(super.possibleDateRange.from) ? super.possibleDateRange.from : from,
      to: to.isAfter(super.possibleDateRange.to) ? super.possibleDateRange.to : to
    };
  }
  get quickDateRanges() {
    const ranges = super.quickDateRanges.concat([this.type === 'STUDENT' && this.__class ? {
      label: 'Class\'s School Year',
      value: {
        from: dayjs(this.__class.startDate, 'YYYY-MM-DD'),
        to: dayjs(this.__class.endDate, 'YYYY-MM-DD')
      }
    } : {
      label: 'Current School Year',
      value: {
        from: dayjs(this.__semester.earliestDate, 'YYYY-MM-DD'),
        to: dayjs(this.__semester.latestDate, 'YYYY-MM-DD')
      }
    }]);
    ranges.forEach(r => r.value = this.adjustDateRange(r.value));
    return ranges;
  }
}