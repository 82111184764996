import { Pagination, Skeleton, Tag } from 'antd';
import { useContext, useMemo, useState } from 'react';
import { uniqBy } from 'lodash';
import AC from '../../AccessControl';
import ErrorMessage from '../../ErrorMessage';
import QuickLinks from '../../panels/QuickLinks';
import * as ENTITY_QUERIES from '../../../helpers/gqlQueries/ui/context';
import { useQuery } from '../../../lib/hooks';
import UiContext from '../../../lib/UiContext';
import styles from './Results.module.scss';
const pageSize = 25;
const nameFor = datum => {
  switch (datum.__typename) {
    case 'District':
      return `${datum.name}, ${datum.state.abbreviation}`;
    default:
      return datum.name;
  }
};
const Result = ({
  id,
  typename
}) => {
  const {
    gradeLevels
  } = useContext(UiContext);
  const {
    loading,
    error,
    data
  } = useQuery(ENTITY_QUERIES[`UI_GET_${typename.toUpperCase()}_CONTEXT`], {
    variables: {
      [`${typename}Id`]: id
    }
  });
  if (loading) {
    return <li><Skeleton /></li>;
  }
  if (error) {
    return <li><ErrorMessage message={`Failed to load ${typename}'s data.`} /></li>;
  }
  const {
    district,
    school,
    grade,
    semester,
    students,
    type,
    studentID,
    email,
    schools,
    classes
  } = data[`${typename}ById`];
  const metaClasses = classes?.filter(c => c.current) ?? [];
  const metaSchools = uniqBy([...metaClasses.map(c => c.school), ...(schools ?? []), school].filter(Boolean), 'id');
  const metaDistricts = uniqBy([...metaSchools.map(s => s.district), district].filter(Boolean), 'id');
  const meta = metaDistricts.map(d => [<div key={`district-${d.id}`} className={styles["district-group"]}>
			<div>
				<span>District</span>: <span children={nameFor(d)} />
			</div>
			<div children={metaSchools.filter(s => s.district?.id === d.id).map(s => [<div key={`school-${s.id}`} className={styles["school-group"]}>
						<div>
							<span>School</span>: <span children={nameFor(s)} />
						</div>
						<div children={metaClasses.filter(c => c.school.id === s.id).map(c => [<div key={`class-${c.id}`}>
									<span>Class</span>: <span children={nameFor(c)} />
								</div>])} />
					</div>])} />
		</div>]);
  const details = typename === 'user' ? <div>
			<div style={{
      display: 'flex',
      gap: 8
    }}>
				<Tag color="geekblue" children={type.split('_').join(' ')} />
				<span style={{
        color: '#dcdedc'
      }}>ID: {id}</span>
			</div>
			<div children={type === 'STUDENT' ? studentID ? `Student ID: ${studentID}` : 'No Student ID' : email} />
		</div> : typename === 'class' ? <div>
				<div style={{
      display: 'flex',
      gap: 8
    }}>
					<span children={grade} />
					<span children={`${students.length} student${students.length === 1 ? '' : 's'}`} />
				</div>
				<div children={semester} />
			</div> : null;
  return <li data-sentry-component="Result" data-sentry-source-file="Results.jsx">
			<article>
				<header>
					<h1>
						<AC.Link label={nameFor(data[`${typename}ById`])} href={{
            pathname: `/reports/${typename}/overview`,
            query: {
              id
            }
          }} data-sentry-element="unknown" data-sentry-source-file="Results.jsx" />
					</h1>
					<QuickLinks title="Quick Links" type={typename} id={id} dateSpan={{}} display="dropdown" data-sentry-element="QuickLinks" data-sentry-source-file="Results.jsx" />
				</header>
				<section>
					{details}
					{meta.length > 0 && <div className={styles["meta"]} children={meta} />}
				</section>
			</article>
		</li>;
};
const Results = ({
  results
}) => {
  const [page, setPage] = useState(1);
  const pageResults = useMemo(() => results.slice((page - 1) * pageSize, page * pageSize), [results, page]);
  const paginationProps = {
    simple: true,
    size: "small",
    showTotal: (total, [begin, end]) => `Showing ${begin} - ${end} of ${total}`,
    total: results.length,
    showSizeChanger: false,
    pageSize: pageSize,
    current: page,
    onChange: p => setPage(p)
  };
  return <>
			<div style={{
      textAlign: 'right'
    }}>
				<Pagination {...paginationProps} data-sentry-element="Pagination" data-sentry-source-file="Results.jsx" />
			</div>
			<ul className={styles["reports-search-result-list"]} children={pageResults.map(({
      id,
      __typename
    }) => <Result id={id} typename={__typename.toLowerCase()} key={`${__typename}:${id}`} />)} />
			<div style={{
      textAlign: 'right'
    }}>
				<Pagination {...paginationProps} data-sentry-element="Pagination" data-sentry-source-file="Results.jsx" />
			</div>
		</>;
};
export default Results;