import { Button, Col, Modal, Row } from 'antd';
import React, { useState } from 'react';
import { PlayCircleOutlined } from '@ant-design/icons';
const VideoPopUp = ({
  title,
  videoUrl,
  type = 'primary',
  buttonTitle,
  buttonProps = {}
}) => {
  const [modal, updateModal] = useState({
    visible: false,
    embedLink: ''
  });
  return <div className="tutorial-video-container" data-sentry-component="VideoPopUp" data-sentry-source-file="VideoPopUp.jsx">
      <Button type={type} {...buttonProps} icon={<PlayCircleOutlined />} onClick={() => {
      updateModal({
        visible: true,
        embedLink: videoUrl
      });
    }} data-sentry-element="Button" data-sentry-source-file="VideoPopUp.jsx">
        <span>{buttonTitle}</span>
      </Button>
      <Modal title={title} destroyOnClose className="video-player-modal" centered open={modal.visible} footer={null} onCancel={() => updateModal({
      visible: false
    })} data-sentry-element="Modal" data-sentry-source-file="VideoPopUp.jsx">
        <iframe width="100%" height="600" src={modal.embedLink} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; modestbranding"></iframe>
      </Modal>
    </div>;
};
export default VideoPopUp;