import { Button } from 'antd';
import { useRouter } from 'next/router';
const ButtonLink = ({
  url,
  as,
  options,
  children,
  onClick,
  ...buttonProps
}) => {
  const router = useRouter();
  const onClickHandler = () => {
    const performRedirect = (onClick && onClick()) ?? true;
    performRedirect && router.push(url, as, options);
  };
  return <Button {...buttonProps} onClick={onClickHandler} data-sentry-element="Button" data-sentry-component="ButtonLink" data-sentry-source-file="ButtonLink.jsx">{children}</Button>;
};
export default ButtonLink;