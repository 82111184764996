import { Tag } from 'antd';
import { sortBy, uniqBy } from 'lodash';
import { Fragment } from 'react';
import dayjs from 'dayjs';
import EnrollmentPicker from '../../../components/parameters/EnrollmentPicker';
import { formatDatetime } from '../../../helpers/datetime';
const StudentInfoHeader = uiContext => {
  const queryParams = {
    id: uiContext.entity.id,
    startDate: uiContext.dateSpan.from.format('YYYY-MM-DD'),
    endDate: uiContext.dateSpan.to.format('YYYY-MM-DD'),
    classId: uiContext.otherParams.classId
  };
  const enrollments = sortBy(uiContext.entity.enrollments?.filter(e => !e.class.phantomClass) ?? [], ['from', 'to']).reverse().map(e => e.class).concat(uniqBy(uiContext.entity.enrollments.map(e => e.class).filter(c => c.phantomClass), c => c.id).map(c => ({
    ...c,
    name: `Not in a class, ${c.school?.name ?? 'Unknown School'}`
  })));
  if (enrollments.length > 1) {
    enrollments.unshift({
      id: null,
      name: 'All Classes',
      school: uiContext.entity.enrollments.filter(e => ['HOMEROOM', null].includes(e.class.type)).find(e => dayjs().isBetween(e.from, e.to, 'day', '[]')).class.school,
      phantomClass: true
    });
  }
  const currentClass = enrollments.find(c => c.id === uiContext.otherParams.classId) ?? enrollments[0];
  const menu = [{
    key: 'overview',
    title: 'Overview',
    icon: false,
    link: {
      pathname: `/reports/student/overview`,
      query: queryParams
    }
  }, {
    key: 'usage',
    title: 'Usage',
    icon: false,
    link: {
      pathname: `/reports/student/usage`,
      query: queryParams
    }
  }, {
    key: 'activity-logs',
    title: 'Activity Log',
    icon: true,
    children: [{
      key: 'activity-log',
      title: 'All Activities',
      link: {
        pathname: `/reports/student/activity-log`,
        query: queryParams
      }
    }, {
      key: 'reading-log',
      title: 'Reading Log',
      icon: false,
      link: {
        pathname: `/reports/student/reading-log`,
        query: queryParams
      }
    }, {
      key: 'writing-log',
      title: 'Writing Log',
      icon: false,
      link: {
        pathname: `/reports/student/writing-log`,
        query: queryParams
      }
    }]
  }, {
    key: 'proficiency',
    title: 'Proficiency',
    icon: false,
    link: {
      pathname: `/reports/student/proficiency`,
      query: queryParams
    }
  }, {
    key: 'growth',
    title: 'Growth',
    icon: false,
    link: {
      pathname: `/reports/student/growth`,
      query: queryParams
    }
  }, {
    key: 'assignmentsMenu',
    title: 'Assignments',
    icon: true,
    children: [{
      key: 'assignments',
      title: 'Assignments',
      icon: false,
      link: {
        pathname: '/reports/student/assignments',
        query: queryParams
      }
    }, {
      key: 'sharedLinks',
      title: 'Shared Links',
      link: {
        pathname: '/reports/student/shared-links',
        query: queryParams
      }
    }].filter(e => e)
  }];
  const breadcrumbs = [currentClass.school && <Fragment key="c.s.district">
        {currentClass.school.district.name}, {currentClass.school.district.state.abbreviation}{' '}
        {currentClass.school.district.isMic && <Tag color="green">MIC</Tag>}
      </Fragment>, currentClass.school?.name ?? 'Unknown School', <EnrollmentPicker key="enrollment-picker" enrollments={enrollments} currentClass={currentClass} />].filter(e => e != null);
  const extra = [<div className="interaction-descriptions" key="account-creation">
      Account creation:{' '}
      <span className="interaction-date">
        {uiContext.entity.creationDate ? formatDatetime(uiContext.entity.creationDate) : 'Not Available'}
      </span>
    </div>, <div className="interaction-descriptions" key="last-interaction">
      Last Interaction:{' '}
      <span className="interaction-date">
        {uiContext.entity.latestInteraction ? formatDatetime(uiContext.entity.latestInteraction) : 'Not Available'}
      </span>
    </div>];
  const title = <>Student: {uiContext.entity.name}</>;
  return {
    menu,
    breadcrumbs,
    extra,
    title
  };
};
export default StudentInfoHeader;