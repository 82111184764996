const ClassAvatar = ({
  of: {
    avatarImageUrl,
    avatarBorderColor,
    avatarBackgroundColor
  },
  size = '50px'
}) => <img src={avatarImageUrl} style={{
  width: size,
  height: size,
  boxSizing: 'border-box',
  borderRadius: '50%',
  border: `calc(${size} * 0.04) solid #${avatarBorderColor}`,
  background: `#${avatarBackgroundColor}`
}} data-sentry-component="ClassAvatar" data-sentry-source-file="ClassAvatar.jsx" />;
export default ClassAvatar;