import ReportsSearch from '../../navigation/ReportsSearch';
import ToolkitsSearch from '../../navigation/ToolkitsSearch';
import { ReportsAccessProvider } from '../../../lib/ReportsAccessContext';
const searchComponentByType = {
  reports: ({
    contextWrap,
    ...props
  }) => contextWrap ? <ReportsAccessProvider children={<ReportsSearch {...props} />} /> : <ReportsSearch {...props} />,
  toolkits: ToolkitsSearch
};
const Search = ({
  searchType,
  ...props
}) => {
  const Component = searchComponentByType[searchType];
  return <Component {...props} data-sentry-element="Component" data-sentry-component="Search" data-sentry-source-file="Search.jsx" />;
};
export default Search;