import dayjs from 'dayjs';
import Model from './Model';
export default class District extends Model {
  constructor(entityData) {
    if (entityData.__typename !== 'District') {
      throw new Error('invalid data supplied');
    }
    super(entityData);
  }
  get defaultDateRange() {
    const from = dayjs(this.startDate, 'YYYY-MM-DD');
    const to = dayjs();
    return this.adjustDateRange({
      from,
      to
    });
  }
  get possibleDateRange() {
    const from = dayjs(this.startDate, 'YYYY-MM-DD');
    const to = dayjs().subtract(1, 'days');
    return {
      // use the oldest date
      from: from.isBefore(super.possibleDateRange.from) ? from : super.possibleDateRange.from,
      to: to.isAfter(super.possibleDateRange.to) ? super.possibleDateRange.to : to
    };
  }
  get quickDateRanges() {
    const semester = this.semestersV2.reduce((min, v) => min.order < v.order ? min : v, {});
    const ranges = [30, 60].map(n => ({
      label: `Last ${n} Days`,
      value: {
        from: dayjs().subtract(n + 1, 'days'),
        to: dayjs().subtract(1, 'days')
      }
    })).concat([{
      label: `Current School Year`,
      value: {
        from: dayjs(semester.earliestDate, 'YYYY-MM-DD'),
        to: dayjs(semester.latestDate, 'YYYY-MM-DD')
      }
    }, {
      label: `District Implementation`,
      value: {
        from: dayjs(this.startDate, 'YYYY-MM-DD'),
        to: dayjs()
      }
    }]);
    ranges.forEach(r => r.value = this.adjustDateRange(r.value));
    return ranges;
  }
}