import gql from 'graphql-tag';
export const reportsAcceessDistricts = gql`
  query ReportsAcceessDistricts {
    districts {
      id
      name
      startDate
      districtSchools {
        id
        semesters
      }
    }
  }
`;
export const myReports = gql`
  query MyReports {
    me {
      user {
        id
        type
      }
      access {
        system
        highestReportLevel
      }
      reports {
        programGroups {
          id
          name
        }
        states {
          id
          name
        }
        districts {
          id
          name
          startDate
          districtSchools {
            id
            semesters
          }
        }
        schools {
          id
          name
          semestersV2 {
            uid
            order
            name
            latestDate
            earliestDate
          }
          semesters
          district {
            id
            name
          }
        }
        classes {
          id
          name
          current
          startDate
          endDate
          semester
          studentCount
          school {
            id
            name
            district {
              id
              name
            }
          }
        }
        users {
          id
          name
          type
          startDate: creationDate
        }
      }
    }
  }
`;
export const mySession = gql`
  query MySession {
    me {
      user {
        id
        type
        name
        first_name
        last_name
        state {
          abbreviation
        }
      }
      access {
        system
      }
      menu {
        imgSrc
        label
        type
        url
        buttonColor
      }
    }
  }
`;