import AC from '../AccessControl';
import { Input } from 'antd';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
const pathname = '/toolkits/search';
const ToolkitsSearch = props => {
  const router = useRouter();
  const [value, setValue] = useState('');
  useEffect(() => {
    router.pathname === pathname && setValue(router.query.kw);
  }, [router]);
  return <AC.CheckAccess rule={AC.ruleFromHref({
    pathname
  })} data-sentry-element="unknown" data-sentry-component="ToolkitsSearch" data-sentry-source-file="ToolkitsSearch.jsx">
			<Input.Search {...props} style={{
      maxWidth: 400
    }} value={value} onChange={e => setValue(e.target.value)} onSearch={kw => router.push({
      pathname,
      query: {
        kw
      }
    })} data-sentry-element="unknown" data-sentry-source-file="ToolkitsSearch.jsx" />
		</AC.CheckAccess>;
};
export default ToolkitsSearch;