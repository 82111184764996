import { Menu } from 'antd';
import { FolderOutlined } from '@ant-design/icons';
import breadcrumbFor from './breadcrumbFor';
import ErrorMessage from '../../../components/ErrorMessage';
import { LoadingMenu } from '../../../components/ui/LoadingCards';
import { UI_STATES_QUERY } from '../../../helpers/gqlQueries/ui/tree';
import { useQuery } from '../../../lib/hooks';
export default function StatesMenu({
  updateState
}) {
  const {
    loading,
    error,
    data
  } = useQuery(UI_STATES_QUERY);
  if (loading) {
    return <LoadingMenu />;
  }
  if (error) {
    return <ErrorMessage message="Error loading data." />;
  }
  const states = [...data.states].sort((a, b) => a.name > b.name ? 1 : -1);
  return <Menu onClick={e => updateState(breadcrumbFor(states.find(s => s.id === e.key)))} items={[{
    type: 'group',
    key: 'states',
    children: states.map(state => ({
      key: state.id,
      label: <>
              <FolderOutlined /> {state.name}
            </>
    }))
  }]} data-sentry-element="Menu" data-sentry-component="StatesMenu" data-sentry-source-file="StatesMenu.jsx" />;
}