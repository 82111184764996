import styles from './CustomButton.module.scss';
import AC from '../AccessControl';
const CustomButton = ({
  url,
  type,
  iconURL,
  title,
  color,
  coverURL,
  secondaryColor
}) => {
  const style = {
    primary: {
      backgroundColor: color
    },
    full: {
      backgroundColor: color
    },
    card: {
      background: `linear-gradient(180deg, ${color}, ${secondaryColor})`
    },
    lined: {
      backgroundColor: `${color}20`,
      color: color
    }
  };
  return <AC.Link href={url} label={<div className={`${styles['custom-button-default']} ${styles[type]}`} style={style[type]}>
          {iconURL && <img src={iconURL} height={40} style={{
      backgroundColor: color
    }} alt={title} />}
          {coverURL && <img src={coverURL} />}
          <span className={styles.text} children={title} />
        </div>} data-sentry-element="unknown" data-sentry-component="CustomButton" data-sentry-source-file="CustomButton.jsx" />;
};
export default CustomButton;