import Icon from '@ant-design/icons';
const ReportsSvg = () => <svg viewBox="0 0 64 64" width={20} height={20} fill="currentColor" data-sentry-element="svg" data-sentry-component="ReportsSvg" data-sentry-source-file="NavIcons.jsx">
    <path d="m57.79,53.26H9.45V9.02c0-.95-.77-1.72-1.72-1.72s-1.72.77-1.72,1.72v47.67h51.78c.95,0,1.72-.77,1.72-1.72s-.77-1.72-1.72-1.72h0" data-sentry-element="path" data-sentry-source-file="NavIcons.jsx" />
    <path d="m26.7,38.19v10.95c0,.95.77,1.72,1.72,1.72s1.72-.77,1.72-1.72v-10.95c0-.95-.77-1.72-1.72-1.72-.95,0-1.72.77-1.72,1.72h0Z" data-sentry-element="path" data-sentry-source-file="NavIcons.jsx" />
    <path d="m17.86,40.45v8.69c0,.95.77,1.72,1.72,1.72s1.72-.77,1.72-1.72v-8.69c0-.95-.77-1.72-1.72-1.72s-1.72.77-1.72,1.72" data-sentry-element="path" data-sentry-source-file="NavIcons.jsx" />
    <path d="m35.22,33.09v16.05c0,.95.77,1.72,1.72,1.72s1.72-.77,1.72-1.72v-16.05c0-.95-.77-1.72-1.72-1.72s-1.72.77-1.72,1.72" data-sentry-element="path" data-sentry-source-file="NavIcons.jsx" />
    <path d="m44.07,27.29v21.85c0,.95.77,1.72,1.72,1.72.95,0,1.72-.77,1.72-1.72v-21.85c0-.95-.77-1.72-1.72-1.72-.95,0-1.72.77-1.72,1.72h0" data-sentry-element="path" data-sentry-source-file="NavIcons.jsx" />
    <path d="m14.21,33.67h.17l2.23-.22c10.74-1.07,20.8-5.78,28.51-13.34l5.24-5.14.12,3.41c.03.93.8,1.66,1.72,1.66h.06c.95-.03,1.69-.83,1.66-1.77l-.23-6.61c-.05-1.45-1.26-2.58-2.71-2.53-.12,0-.24.02-.35.04l-7.24,1.23c-.94.14-1.59,1.01-1.45,1.96.13.85.86,1.48,1.73,1.47.1,0,.2-.03.3-.05l3.29-.56-4.53,4.45c-7.15,7.01-16.48,11.37-26.44,12.36l-2.23.22c-.94.12-1.61.99-1.48,1.93.11.83.81,1.47,1.65,1.5" data-sentry-element="path" data-sentry-source-file="NavIcons.jsx" />
  </svg>;
export const ReportsIcon = props => <Icon component={ReportsSvg} {...props} data-sentry-element="Icon" data-sentry-component="ReportsIcon" data-sentry-source-file="NavIcons.jsx" />;
const ToolkitsSvg = () => <svg viewBox="0 0 64 64" width={20} height={20} fill="currentColor" data-sentry-element="svg" data-sentry-component="ToolkitsSvg" data-sentry-source-file="NavIcons.jsx">
    <path fill="currentColor" d="m42.24,51.1q3.29.43,3.29,3.7c0,.28-.02.57,0,.85.1,1.13-.46,1.46-1.52,1.44-2.82-.05-5.64-.02-8.46-.02-5.17,0-10.34-.03-15.5.02-1.28.01-1.83-.38-1.73-1.68.07-.89.05-1.79,0-2.68-.06-1.09.41-1.56,1.5-1.5.59.04,1.19,0,1.95,0,0-2.28.04-4.38-.02-6.49-.03-1.16.37-1.62,1.55-1.56,1.36.07,2.73.08,4.08-.02.43-.03,1.06-.44,1.18-.82.4-1.19.69-2.44.86-3.69.25-1.84-.24-3.23-1.96-4.42-1.56-1.09-2.63-2.88-3.96-4.32-.44-.47-.97-1-1.56-1.16-3.44-.96-5.77-3.24-7.55-6.18-2.08-3.41-2.99-7.16-3.08-11.14-.03-1.33.56-1.89,1.86-1.86,1.68.03,3.36,0,4.95,0,.14-.22.23-.29.23-.37.08-2.36-.16-2.35,2.48-2.34,7.38,0,14.75,0,22.13,0q2.48,0,2.7,2.71c1.54,0,3.11,0,4.68,0,2.05,0,2.4.38,2.3,2.38-.21,4.26-1.32,8.24-3.83,11.75-1.8,2.52-4.06,4.41-7.18,5.1-.38.09-.78.46-1.01.82-1.32,2.09-2.85,3.93-4.95,5.31-.57.38-1.14,1.32-1.09,1.97.12,1.9.5,3.78.89,5.65.06.27.68.58,1.06.6,1.31.07,2.64.11,3.94,0,1.41-.11,1.8.49,1.75,1.81-.08,2.01-.02,4.03-.02,6.1ZM14.24,12.43c.06,5.49,3.57,11.85,6.59,12.71-.7-3.85-1.42-7.85-2.2-11.84-.06-.33-.52-.8-.82-.83-1.15-.1-2.32-.04-3.57-.04Zm31.48.08c-.92,4.38-1.82,8.62-2.7,12.77,3.58-1.63,7.03-8.1,6.64-12.77h-3.94Z" data-sentry-element="path" data-sentry-source-file="NavIcons.jsx" />
  </svg>;
export const ToolkitsIcon = props => <Icon component={ToolkitsSvg} {...props} data-sentry-element="Icon" data-sentry-component="ToolkitsIcon" data-sentry-source-file="NavIcons.jsx" />;
const HomeSvg = () => <svg viewBox="0 0 64 64" width={20} height={20} fill="currentColor" data-sentry-element="svg" data-sentry-component="HomeSvg" data-sentry-source-file="NavIcons.jsx">
      <path d="m31.2,20.9l-19.28,15.64v16.88c0,.91.75,1.65,1.67,1.65l11.72-.03c.92,0,1.67-.75,1.67-1.66h0v-9.86c0-.91.75-1.64,1.67-1.64h6.69c.92,0,1.67.74,1.67,1.65v9.85c0,.91.74,1.65,1.67,1.65,0,0,0,0,0,0l11.71.03c.92,0,1.67-.74,1.67-1.65v-16.89l-19.27-15.63c-.47-.37-1.13-.37-1.6,0Zm30.45,10.63l-8.74-7.1v-14.26c0-.68-.56-1.24-1.25-1.24h-5.86c-.69,0-1.25.55-1.25,1.24v7.47l-9.37-7.59c-1.85-1.5-4.52-1.5-6.38,0L2.34,31.53c-.53.44-.61,1.21-.17,1.74,0,0,0,0,0,0l2.67,3.19c.44.53,1.23.6,1.77.17,0,0,0,0,0,0l24.59-19.96c.47-.37,1.13-.37,1.6,0l24.59,19.95c.53.44,1.32.36,1.77-.16l2.67-3.19c.44-.53.36-1.31-.17-1.74,0,0,0,0,0,0Z" data-sentry-element="path" data-sentry-source-file="NavIcons.jsx" />
  </svg>;
export const HomeIcon = props => <Icon component={HomeSvg} {...props} data-sentry-element="Icon" data-sentry-component="HomeIcon" data-sentry-source-file="NavIcons.jsx" />;
const ClassMgmtSvg = () => <svg width={20} height={20} viewBox="0 0 416 416" data-sentry-element="svg" data-sentry-component="ClassMgmtSvg" data-sentry-source-file="NavIcons.jsx">
    <path fill="currentColor" stroke="currentColor" strokeWidth="1" d="M 64.00,286.00
         C 64.48,264.26 73.75,236.29 86.90,219.00
           90.45,214.33 97.47,207.18 102.00,203.46
           105.15,200.88 110.71,197.96 111.80,193.96
           113.22,188.73 108.30,185.84 105.00,182.83
           99.65,177.95 94.41,171.59 91.32,165.00
           76.33,132.97 101.32,98.23 136.00,99.01
           154.80,99.44 171.34,111.62 178.17,129.00
           179.96,133.55 181.99,143.17 180.69,147.90
           179.74,151.34 175.67,153.29 173.00,155.47
           168.08,159.47 163.14,164.66 159.81,170.00
           148.13,187.97 148.98,208.99 159.81,227.00
           164.78,234.98 169.15,237.74 174.00,244.00
           164.11,250.59 156.52,257.04 149.75,267.00
           149.75,267.00 139.51,284.98 139.51,284.98
           137.68,286.23 134.18,286.00 132.00,286.00
           132.00,286.00 64.00,286.00 64.00,286.00 Z
         M 239.00,243.00
         C 239.00,243.00 250.69,226.00 250.69,226.00
           258.55,211.71 260.48,194.45 254.91,179.00
           252.76,173.02 247.97,165.73 243.71,161.04
           235.67,152.19 232.71,155.61 233.01,143.00
           233.19,135.56 236.09,127.24 240.09,121.00
           258.34,92.48 301.47,91.88 319.73,121.00
           329.05,136.34 329.52,156.57 319.73,172.00
           316.79,177.06 314.42,179.73 310.00,183.72
           307.63,185.86 304.12,188.30 304.58,191.96
           305.05,195.75 311.17,199.14 314.00,201.46
           321.04,207.24 327.15,214.48 332.25,222.00
           341.99,236.34 347.80,253.01 350.75,270.00
           350.75,270.00 353.00,286.00 353.00,286.00
           353.00,286.00 280.00,286.00 280.00,286.00
           277.82,286.00 274.32,286.23 272.49,284.98
           270.75,283.78 264.98,271.21 262.95,268.00
           256.25,257.42 249.19,250.24 239.00,243.00 Z
         M 134.00,334.00
         C 134.00,334.00 137.21,313.00 137.21,313.00
           140.22,298.74 145.54,285.13 153.67,273.00
           158.74,265.44 163.78,259.35 171.00,253.67
           174.13,251.21 181.06,247.57 182.21,243.96
           183.49,239.92 179.52,237.31 177.00,234.91
           170.43,228.68 165.21,223.32 161.32,215.00
           157.52,206.86 157.90,202.65 158.00,194.00
           158.25,172.67 175.63,155.09 196.00,151.26
           199.14,150.67 202.83,149.96 206.00,150.18
           239.66,152.59 261.46,184.27 248.55,216.00
           246.18,221.82 241.61,228.50 236.96,232.70
           234.45,234.97 229.04,238.02 229.82,242.00
           230.51,245.49 236.26,248.81 239.00,250.90
           245.07,255.53 249.98,260.89 254.52,267.00
           268.25,285.44 277.00,310.97 277.00,334.00
           277.00,334.00 134.00,334.00 134.00,334.00 Z" data-sentry-element="path" data-sentry-source-file="NavIcons.jsx" />
  </svg>;
export const ClassMgmtIcon = props => <Icon component={ClassMgmtSvg} {...props} data-sentry-element="Icon" data-sentry-component="ClassMgmtIcon" data-sentry-source-file="NavIcons.jsx" />;