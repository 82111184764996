import dayjs from 'dayjs';
import dayjsPluginIsBetween from 'dayjs/plugin/isBetween';
import dayjsPluginIsSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import dayjsPluginIsoWeek from 'dayjs/plugin/isoWeek';
import dayjsPluginUTC from 'dayjs/plugin/utc';
import dayjsPluginParseFormat from 'dayjs/plugin/customParseFormat';
import dayjsPluginUpdateLocale from 'dayjs/plugin/updateLocale';
dayjs.extend(dayjsPluginIsBetween);
dayjs.extend(dayjsPluginIsSameOrBefore);
dayjs.extend(dayjsPluginIsoWeek);
dayjs.extend(dayjsPluginParseFormat);
dayjs.extend(dayjsPluginUTC);
dayjs.extend(dayjsPluginUpdateLocale);
dayjs.updateLocale('en', {
  weekStart: 1
});

// https://github.com/iamkun/dayjs/issues/1596
const utcFix = (option, dayjsClass, dayjsFactory) => {
  const originalUtc = dayjsFactory.utc;
  dayjsFactory.utc = (config, formats, strict) => {
    if (Array.isArray(formats)) {
      for (const format of formats) {
        const date = originalUtc(config, format, strict);
        if (date.isValid()) {
          return date;
        }
      }
      return dayjs('Invalid Date');
    }
    return originalUtc(config, formats, strict);
  };
};
dayjs.extend(utcFix);
import { ApolloProvider } from '@apollo/client';
import Head from 'next/head';
import MainLayout from './_app/MainLayout';
import ScrollRestore from './_app/ScrollRestore';
import SessionController from './_app/SessionController';
import Theme from './_app/Theme';
import UiContextController from './_app/UiContextController';
import AC from '../components/AccessControl';
import withApollo from '../lib/withApollo';
const PreviewAppCache = () => ["https://www.myf2b.com/zoom_builds/releases/enterprise/main/web/index.html?appAssetsURL=https%3A%2F%2Fwww.myf2b.com%2Fzoom_builds%2Freleases%2Fenterprise%2Fmain%2Fweb%2F&bookmarks=false&debug=false&language=en&moduleName=cache_wc&moduleKey=0&reporting=false&secureURL=https%3A%2F%2Fwww.myf2b.com&userId=0&userType=Student", "https://www.myf2b.com/zoom_builds/releases/cku_red/main/web/index.html?appAssetsURL=https%3A%2F%2Fwww.myf2b.com%2Fzoom_builds%2Freleases%2Fcku_red%2Fmain%2Fweb%2F&bookmarks=false&debug=false&language=en&moduleName=cache_wc&moduleKey=0&reporting=false&secureURL=https%3A%2F%2Fwww.myf2b.com&userId=0&userType=Student", "https://www.myf2b.com/zoom_builds/releases/cku_orange/main/web/index.html?appAssetsURL=https%3A%2F%2Fwww.myf2b.com%2Fzoom_builds%2Freleases%2Fcku_orange%2Fmain%2Fweb%2F&bookmarks=false&debug=false&language=en&moduleName=cache_wc&moduleKey=0&reporting=false&secureURL=https%3A%2F%2Fwww.myf2b.com&userId=0&userType=Student"].map(url => <iframe style={{
  display: 'none'
}} src={url} key={url} />);
const App = ({
  Component,
  pageProps,
  err,
  apollo
}) => <ScrollRestore data-sentry-element="ScrollRestore" data-sentry-component="App" data-sentry-source-file="_app.jsx">
    <ApolloProvider client={apollo} data-sentry-element="ApolloProvider" data-sentry-source-file="_app.jsx">
      <Head data-sentry-element="Head" data-sentry-source-file="_app.jsx">
        <title key="title">Footsteps2Brilliance</title>
        <link rel="icon" type="image/png" sizes="64x64" href="/favicon/favicon_64.png" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" key="viewport" data-sentry-element="meta" data-sentry-source-file="_app.jsx" />
      </Head>
      <Theme data-sentry-element="Theme" data-sentry-source-file="_app.jsx">
        <SessionController data-sentry-element="SessionController" data-sentry-source-file="_app.jsx">
          <UiContextController data-sentry-element="UiContextController" data-sentry-source-file="_app.jsx">
            <MainLayout subLayout={Component.subLayout} data-sentry-element="MainLayout" data-sentry-source-file="_app.jsx">
              <AC.Page data-sentry-element="unknown" data-sentry-source-file="_app.jsx">
                {/* Workaround for https://github.com/vercel/next.js/issues/8592 */}
                <Component {...pageProps} err={err} data-sentry-element="Component" data-sentry-source-file="_app.jsx" />
              </AC.Page>
            </MainLayout>
          </UiContextController>
          <PreviewAppCache data-sentry-element="PreviewAppCache" data-sentry-source-file="_app.jsx" />
        </SessionController>
      </Theme>
    </ApolloProvider>
  </ScrollRestore>;
export default withApollo(App);