import { Button, Tag } from 'antd';
import ClassAvatar from '../../../components/images/ClassAvatar';
import { ClassMgmtIcon } from '../../../components/images/NavIcons';
import { capitalize } from 'lodash';
import { useContext } from 'react';
const ClassInfoHeader = uiContext => {
  const queryParams = {
    id: uiContext.entity.id,
    startDate: uiContext.dateSpan.from.format('YYYY-MM-DD'),
    endDate: uiContext.dateSpan.to.format('YYYY-MM-DD')
  };
  const menu = [{
    key: 'overview',
    title: 'Overview',
    icon: false,
    link: {
      pathname: `/reports/class/overview`,
      query: queryParams
    }
  }, {
    key: 'usage-tab',
    title: 'Usage',
    icon: true,
    children: [{
      key: 'usage',
      title: 'Usage Summary',
      link: {
        pathname: `/reports/class/usage`,
        query: queryParams
      }
    }, {
      key: 'attendance',
      title: 'Attendance Report',
      link: {
        pathname: '/reports/class/attendance',
        query: queryParams
      }
    }]
  }, {
    key: 'activity-log',
    title: 'Activity Log',
    icon: true,
    children: [{
      key: 'activities',
      title: 'All Activities',
      icon: false,
      link: {
        pathname: `/reports/class/activities`,
        query: queryParams
      }
    }, {
      key: 'writing',
      title: 'Writing',
      link: {
        pathname: `/reports/class/writing`,
        query: queryParams
      }
    }]
  }, {
    key: 'proficiency',
    title: 'Proficiency (groups)',
    icon: false,
    link: {
      pathname: `/reports/class/proficiency`,
      query: queryParams
    }
  }, {
    key: 'growth',
    title: 'Growth',
    icon: false,
    link: {
      pathname: `/reports/class/growth`,
      query: queryParams
    }
  }, {
    key: 'assignmentsMenu',
    title: 'Assignments',
    icon: true,
    children: [{
      key: 'create-assignments',
      title: 'Create New',
      icon: false,
      link: {
        pathname: '/toolkits/[[...path]]',
        query: {}
      }
    }, {
      key: 'assignments',
      title: 'Assignments Gradebook',
      icon: false,
      link: {
        pathname: '/reports/class/assignments-gradebook',
        query: queryParams
      }
    }, {
      key: 'shared-links',
      title: 'Shared Assignments',
      icon: false,
      link: {
        pathname: '/reports/class/shared-links',
        query: queryParams
      }
    }]
  }];
  const breadcrumbs = [<>
      {uiContext.entity.school.district.name}, {uiContext.entity.school.district.state.abbreviation}{' '}
      {uiContext.entity.school.district.isMic ? <Tag color="green">MIC</Tag> : null}
    </>, uiContext.entity.school.name];
  const title = <div style={{
    display: 'flex',
    alignItems: 'center',
    gap: 8
  }}>
      <ClassAvatar of={uiContext.entity} />
      <div>
        Class: {uiContext.entity.name}
        <aside style={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: 20
      }}>
          <small style={{
          color: '#8C8C8C',
          fontWeight: 'normal'
        }}>
            Semester: {uiContext.entity.semester}
          </small>
          <small style={{
          color: '#8C8C8C',
          fontWeight: 'normal'
        }}>
            Grade: {uiContext.entity.grade}
          </small>
          <small style={{
          color: '#8C8C8C',
          fontWeight: 'normal'
        }}>
            Class Type: {capitalize(uiContext.entity.type)}
          </small>
        </aside>
      </div>
    </div>;
  const extra = [<Button href={`${process.env.classManagement}/student_classes/${uiContext.entity.id}`} icon={<ClassMgmtIcon style={{
    verticalAlign: 'middle'
  }} />} children="Class Management" />];
  return {
    menu,
    breadcrumbs,
    title,
    extra
  };
};
export default ClassInfoHeader;