import { Tag } from 'antd';
import { formatDatetime } from '../../../helpers/datetime';
const UserInfoHeader = uiContext => {
  const queryParams = {
    id: uiContext.entity.id,
    startDate: uiContext.dateSpan.from.format('YYYY-MM-DD'),
    endDate: uiContext.dateSpan.to.format('YYYY-MM-DD')
  };
  const menu = [{
    key: 'overview',
    title: 'Overview',
    icon: false,
    link: {
      pathname: `/reports/user/overview`,
      query: queryParams
    }
  }];
  const title = [`${uiContext.entity.type?.split('_').map(w => `${w[0]}${w.slice(1).toLowerCase()}`).join(' ') || uiContext.entity.__typename}: ${uiContext.entity.name}`];
  const breadcrumbs = [<>
      {uiContext.entity.district.name}, {uiContext.entity.district.state.abbreviation}{' '}
      {uiContext.entity.district.isMic && <Tag color="green">MIC</Tag>}{' '}
    </>, uiContext.entity.schools && `${uiContext.entity.schools[0].name}`].filter(e => e);
  const extra = [<div className="interaction-descriptions" key="account-creation">
      Account creation:{' '}
      <span className="interaction-date">
        {uiContext.entity.creationDate ? formatDatetime(uiContext.entity.creationDate) : 'Not Available'}
      </span>
    </div>, <div className="interaction-descriptions" key="last-interaction">
      Last Interaction:{' '}
      <span className="interaction-date">
        {uiContext.entity.latestInteraction ? formatDatetime(uiContext.entity.latestInteraction) : 'Not Available'}
      </span>
    </div>];
  return {
    menu,
    breadcrumbs,
    extra,
    title
  };
};
export default UserInfoHeader;