import gql from 'graphql-tag';
export const UI_PROGRAMS_QUERY = gql`
  query UI_PROGRAMS_QUERY {
    programs: programGroups {
      id
      name
    }
  }
`;
export const UI_PROGRAM_QUERY = gql`
  query UI_PROGRAM_QUERY($programId: ID!) {
    programById: programGroupById(id: $programId) {
      id
      districts {
        id
        name
      }
    }
  }
`;
export const UI_STATES_QUERY = gql`
  query UI_STATES_QUERY {
    states {
      id
      name
    }
  }
`;
export const UI_STATE_QUERY = gql`
  query UI_STATE_QUERY($stateId: ID!) {
    stateById(id: $stateId) {
      id
      districts {
        id
        name
      }
    }
  }
`;
export const UI_DISTRICT_QUERY = gql`
  query UI_DISTRICT_QUERY($districtId: ID!, $semester: String!) {
    districtById(id: $districtId) {
      id
      isMic
      startDate
      schools(semester: $semester, schoolType: [DISTRICT, PARTNER]) {
        id
        name
        type
        semestersV2 {
          name
        }
      }
    }
  }
`;
export const UI_SCHOOL_QUERY = gql`
  query UI_SCHOOL_QUERY($schoolId: ID!, $semester: String!) {
    schoolById(id: $schoolId) {
      id
      classes(semester: $semester) {
        id
        name
        grade
        semester
        phantomClass
        type
        avatarImageUrl
        avatarBorderColor
        avatarBackgroundColor
      }
    }
  }
`;
export const UI_CLASS_QUERY = gql`
  query UI_CLASS_QUERY($classId: ID!) {
    classById(id: $classId) {
      id
      name
      semester
      grade
      startDate
      endDate
      students {
        id
        name
      }
    }
  }
`;