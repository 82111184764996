import dayjs from 'dayjs';
export default class Model {
  constructor(entityData) {
    Object.keys(entityData).forEach(k => Object.defineProperty(this, k, {
      value: entityData[k]
    }));
  }
  get defaultDateRange() {
    return {
      from: dayjs().subtract(61, 'days'),
      to: dayjs().subtract(1, 'days')
    };
  }
  get possibleDateRange() {
    return {
      from: dayjs('2019-08-01', 'YYYY-MM-DD'),
      to: dayjs().subtract(1, 'days')
    };
  }
  get quickDateRanges() {
    return [7, 30, 60, 90].map(n => ({
      label: `Last ${n} Days`,
      value: {
        from: dayjs().subtract(n + 1, 'days'),
        to: dayjs().subtract(1, 'days')
      }
    }));
  }
  adjustDateRange(dateRange) {
    return {
      from: dateRange.from.isBefore(this.possibleDateRange.from) ? this.possibleDateRange.from : dateRange.from,
      to: dateRange.to.isAfter(this.possibleDateRange.to) ? this.possibleDateRange.to : dateRange.to
    };
  }
}
;