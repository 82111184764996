import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import { UiContextProvider } from '../../lib/UiContext';
import { useQuery } from '../../lib/hooks';
import { UI_GET_APPS, UI_GET_SEMESTERS, UI_GET_GRADE_LIST, UI_GET_GRADE_LEVEL_LIST, UI_GET_AGE_GROUPS } from '../../helpers/gqlQueries/ui/general';
import * as ENTITY_QUERIES from '../../helpers/gqlQueries/ui/context';
import Model, * as Models from '../../helpers/models';
const pageToEntity = {
  programGroup: ['program'],
  district: ['district', 'district_schools', 'community', 'partnert_schools'],
  school: ['school'],
  class: ['class'],
  user: ['student', 'user']
};
const queries = {
  apps: UI_GET_APPS,
  grades: UI_GET_GRADE_LIST,
  gradeLevels: UI_GET_GRADE_LEVEL_LIST,
  semesters: UI_GET_SEMESTERS,
  ageGroups: UI_GET_AGE_GROUPS
};
const setQueryParams = (router, newParams) => {
  const newQuery = {
    ...router.query
  };
  Object.keys(newParams).forEach(k => {
    if (newParams[k]) {
      newQuery[k] = newParams[k];
    } else {
      delete newQuery[k];
    }
  });
  router.push({
    pathname: router.pathname,
    query: newQuery
  });
};
const UiContextController = ({
  children
}) => {
  const router = useRouter();
  const entityType = Object.keys(pageToEntity).find(k => pageToEntity[k].includes(router.pathname.split('/')[2]));
  const entityQuery = Object.keys(ENTITY_QUERIES).reduce((qData, key) => {
    const shouldBeKey = `UI_GET_${entityType}_CONTEXT`.toUpperCase() === key;
    const q = useQuery(ENTITY_QUERIES[key], {
      variables: {
        [`${entityType}Id`]: router.query.id
      },
      skip: !(shouldBeKey && router.query.id)
    });
    return shouldBeKey ? q : qData;
  }, {});
  const queryStatuses = {};
  Object.keys(queries).forEach(k => {
    queryStatuses[k] = useQuery(queries[k]);
  });
  const uiContext = {};
  Object.defineProperty(uiContext, 'loading', {
    value: entityQuery.loading || Object.values(queryStatuses).some(status => status.loading)
  });
  Object.defineProperty(uiContext, 'error', {
    value: entityQuery.error || Object.values(queryStatuses).some(status => status.error)
  });
  Object.defineProperty(uiContext, 'entity', {
    value: entityQuery.data?.[`${entityType}ById`]
  });
  Object.keys(queryStatuses).forEach(k => {
    Object.defineProperty(uiContext, k, {
      value: queryStatuses[k].data?.[k]
    });
  });
  if (!uiContext.loading && !uiContext.error) {
    const entityModel = uiContext.entity ? new Models[uiContext.entity.__typename](uiContext.entity, {
      classID: router.query.classId,
      semesters: uiContext.semesters
    }) : new Model({});
    const dateSpan = {};
    Object.defineProperty(dateSpan, 'from', {
      get: () => {
        const t = dayjs(router.query.startDate, 'YYYY-MM-DD');
        return t.isValid() ? t : entityModel?.defaultDateRange.from;
      }
    });
    Object.defineProperty(dateSpan, 'to', {
      get: () => {
        const t = dayjs(router.query.endDate, 'YYYY-MM-DD');
        return t.isValid() ? t : entityModel?.defaultDateRange.to;
      }
    });
    Object.defineProperty(uiContext, 'dateSpan', {
      get: () => dateSpan,
      set: val => {
        const v = (({
          from,
          to
        }) => ({
          startDate: dayjs(from, 'YYYY-MM-DD').format('YYYY-MM-DD'),
          endDate: dayjs(to, 'YYYY-MM-DD').format('YYYY-MM-DD')
        }))(val);
        setQueryParams(router, v);
      }
    });
    Object.defineProperty(uiContext, 'possibleDateSpan', {
      value: entityModel?.possibleDateRange
    });
    Object.defineProperty(uiContext, 'quickDateSpans', {
      value: entityModel?.quickDateRanges
    });
  }
  Object.defineProperty(uiContext, 'app', {
    get: () => uiContext.apps.find(e => e.id === router.query.app),
    set: val => {
      const v = (uiContext.apps.includes(val) ? val : uiContext.apps.find(e => e.id === val))?.id;
      setQueryParams(router, {
        app: v
      });
    }
  });
  Object.defineProperty(uiContext, 'grade', {
    get: () => uiContext.grades.find(e => e.id === router.query.grade),
    set: val => {
      const v = (uiContext.grades.includes(val) ? val : uiContext.grades.find(e => e.id === val))?.id;
      setQueryParams(router, {
        grade: v
      });
    }
  });
  const specialParams = ['id', 'app', 'grade', 'startDate', 'endDate'];
  const otherParams = {};
  Object.keys(router.query).filter(k => !specialParams.includes(k)).forEach(k => {
    Object.defineProperty(otherParams, k, {
      value: router.query[k]
    });
  });
  Object.defineProperty(uiContext, 'otherParams', {
    get: () => otherParams,
    set: val => {
      const v = {
        ...val
      };
      specialParams.forEach(k => {
        delete v[k];
      });
      setQueryParams(router, v);
    }
  });
  return <UiContextProvider value={Object.seal(uiContext)} data-sentry-element="UiContextProvider" data-sentry-component="UiContextController" data-sentry-source-file="UiContextController.jsx">{children}</UiContextProvider>;
};
export default UiContextController;