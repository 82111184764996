import { Skeleton } from 'antd';
import { createContext, useContext } from 'react';
import { camelCase, capitalize, takeWhile, uniq } from 'lodash';
import { useQuery } from './hooks/apollo';
import ErrorMessage from '../components/ErrorMessage';
import { myReports, reportsAcceessDistricts } from '../helpers/gqlQueries/me';
const ReportsAcceessContext = createContext();
const topProgramsLevel = ({
  programGroups
}) => {
  switch (programGroups.length) {
    case 0:
      return null;
    case 1:
      return {
        type: 'Program',
        id: programGroups[0].id,
        name: programGroups[0].name
      };
    default:
      return {
        type: 'Programs',
        name: 'Programs'
      };
  }
};
const menuFor = ({
  __typename: type,
  id,
  name
}) => ({
  type: type === 'ProgramGroup' ? 'Program' : type,
  id: id,
  name: name
});
const userMenu = (type, systemAccess, reports) => {
  if (type === 'NON_STUDENT') {
    if (systemAccess) {
      return {
        topLevel: {
          name: 'Dashboard Reports',
          submenus: [{
            type: 'Programs',
            name: 'Programs'
          }, {
            type: 'States',
            name: 'States'
          }]
        },
        semesters: []
      };
    }
    const menus = Object.entries(reports).filter(([k, v]) => k !== '__typename' && v?.length > 0).map(([k, v]) => v.length === 1 ? menuFor(v[0]) : {
      type: `custom_${k}`,
      name: k === 'programGroups' ? 'Programs' : capitalize(k),
      submenus: v.map(menuFor)
    });
    const topLevel = (() => {
      switch (menus.length) {
        case 0:
          return null;
        case 1:
          return menus[0];
        default:
          return {
            name: 'Dashboard Reports',
            submenus: menus
          };
      }
    })();
    const semesters = uniq([...(reports.districts?.flatMap(d => d.districtSchools.semesters) ?? []), ...(reports.schools?.flatMap(s => s.semesters) ?? []), ...(reports.classes?.map(c => c.semester) ?? [])]);
    return {
      topLevel,
      semesters
    };
  }
  const config = {
    ADMIN: {
      topStatesLevel: () => ({
        type: 'States',
        name: 'States'
      }),
      semesters: () => []
    },
    F2B_EMPLOYEE: {
      topStatesLevel: () => ({
        type: 'States',
        name: 'States'
      }),
      semesters: () => []
    },
    STATE_SUPERINTENDENT: {
      topStatesLevel: () => ({
        type: 'State',
        id: reports.states[0].id,
        name: reports.states[0].name
      }),
      semesters: () => []
    },
    STATE_ADMINISTRATOR: {
      topStatesLevel: () => ({
        type: 'State',
        id: reports.states[0].id,
        name: reports.states[0].name
      }),
      semesters: () => []
    },
    DISTRICT_SUPERINTENDENT: {
      topStatesLevel: () => ({
        type: 'District',
        id: reports.districts[0].id,
        name: reports.districts[0].name
      }),
      semesters: () => reports.districts.flatMap(d => d.districtSchools.semesters)
    },
    DISTRICT_ADMINISTRATOR: {
      topStatesLevel: () => {
        const {
          id,
          name
        } = reports.districts ? reports.districts[0] : reports.schools[0].district;
        return {
          type: 'District',
          id,
          name
        };
      },
      semesters: () => reports.districts?.flatMap(d => d.districtSchools.semesters)
    },
    SCHOOL_PRINCIPAL: {
      topStatesLevel: () => ({
        type: 'School',
        id: reports.schools[0].id,
        name: reports.schools[0].name
      }),
      semesters: () => reports.schools.flatMap(s => s.semesters)
    },
    SCHOOL_ADMINISTRATOR: {
      topStatesLevel: () => ({
        type: 'School',
        id: reports.schools[0].id,
        name: reports.schools[0].name
      }),
      semesters: () => reports.schools.flatMap(s => s.semesters)
    },
    TEACHER: {
      topStatesLevel: () => !reports.classes ? null : reports.classes.map(c => c.school.id).filter((e, i, a) => i === a.indexOf(e)).length > 1 ? {
        type: 'District',
        id: reports.classes[0].school.district.id,
        name: reports.classes[0].school.district.name
      } : {
        type: 'School',
        id: reports.classes[0].school.id,
        name: reports.classes[0].school.name
      },
      semesters: () => reports.classes?.map(c => c.semester)
    }
  };
  if (!config[type]) {
    return null;
  }
  const semesters = config[type].semesters();
  const topStatesLvl = config[type].topStatesLevel();
  const topProgramsLvl = topProgramsLevel(reports);
  const topLevel = topStatesLvl && topProgramsLvl ? {
    name: 'Dashboard Reports',
    submenus: [topProgramsLvl, topStatesLvl]
  } : topProgramsLvl ?? topStatesLvl;
  return {
    topLevel,
    semesters
  };
};
const types = ['User', 'Class', 'School', 'District', 'State', 'ProgramGroup'];
export const ReportsAccessProvider = ({
  children
}) => {
  try {
    const {
      error,
      loading,
      data
    } = useQuery(myReports);
    const {
      user: {
        type
      },
      access: {
        system,
        highestReportLevel
      },
      reports
    } = data?.me || {
      user: {},
      access: {}
    };
    const reportAccessLevel = reports?.programGroups?.length ? 'programGroup' : camelCase(highestReportLevel) || null;
    const {
      error: districtsError,
      loading: districtsLoading,
      data: districtsData
    } = useQuery(reportsAcceessDistricts, {
      skip: reportAccessLevel !== 'state'
    });
    if (loading || districtsLoading) {
      return <Skeleton />;
    }
    if (error) {
      throw error;
    }
    if (districtsError) {
      throw districtsError;
    }
    const reportEntities = reportAccessLevel && (reportAccessLevel === 'class' ? reports.classes.filter(c => c.current) : reportAccessLevel === 'state' ? districtsData.districts : reports[`${reportAccessLevel}s`]).slice(0).sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
    if (reportEntities) {
      reportEntities.type = reportAccessLevel === 'state' ? 'district' : reportAccessLevel;
    }
    ;
    console.debug({
      reportAccessLevel,
      reportEntities
    });
    const menu = userMenu(type, system, reports);
    const accessibleTypes = takeWhile(types, (_, i) => i <= types.map(t => camelCase(t)).indexOf(reportAccessLevel));
    return <ReportsAcceessContext.Provider value={Object.seal({
      reportEntities,
      menu,
      accessibleTypes
    })} children={children} />;
  } catch (e) {
    console.error(e);
    return <ErrorMessage message="Couldn't load report access data. Refresh the page to try again" />;
  }
};
export const useReportsAccess = () => useContext(ReportsAcceessContext);