import { createContext, useEffect, useContext, useState } from 'react';
import SessionContext from './SessionContext';
const AnalyticsContext = createContext();
const initializeAnalytics = () => new Promise((resolve, reject) => {
  const rudderSDK = require('rudder-sdk-js');
  rudderSDK.load(process.env.RUDDERSTACK_WRITE_KEY, process.env.RUDDERSTACK_DATA_PLANE_URL, {
    integrations: {
      All: true
    }
  });
  rudderSDK.ready(() => resolve(rudderSDK));
});
const AnalyticsProvider = ({
  children
}) => {
  const {
    user
  } = useContext(SessionContext);

  // setup analytics
  const [analytics, setAnalytics] = useState();
  useEffect(() => {
    initializeAnalytics().then(sdk => setAnalytics(sdk), e => console.warn('failed to init rudder sdk', e));
  }, []);

  // Called when the page changes
  useEffect(() => analytics?.page());

  // Called when the user changes
  useEffect(() => {
    if (analytics && user) {
      const {
        name,
        type,
        id
      } = user;
      analytics.identify(id, {
        name,
        type
      });
    }
  }, [user, analytics]);
  return <AnalyticsContext.Provider value={analytics} children={children} data-sentry-element="unknown" data-sentry-component="AnalyticsProvider" data-sentry-source-file="analytics.jsx" />;
};
const useAnalytics = () => useContext(AnalyticsContext);
export { AnalyticsProvider, useAnalytics };