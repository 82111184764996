import { LineChartOutlined } from '@ant-design/icons';
import { sortBy } from 'lodash';
import ACLinksMenu from './ACLinksMenu';
import ErrorMessage from '../../../components/ErrorMessage';
import { LoadingMenu } from '../../../components/ui/LoadingCards';
import { UI_CLASS_QUERY } from '../../../helpers/gqlQueries/ui/tree';
import { useQuery } from '../../../lib/hooks';
export default function ClassMenu({
  classId,
  setIsOpen
}) {
  const {
    loading,
    error,
    data
  } = useQuery(UI_CLASS_QUERY, {
    variables: {
      classId
    }
  });
  if (loading) {
    return <LoadingMenu />;
  }
  if (error) {
    return <ErrorMessage message="Error loading data." />;
  }
  const studentLinks = sortBy(data.classById.students, item => item.name.toLowerCase()).map(item => ({
    href: {
      pathname: '/reports/student/overview',
      query: {
        id: item.id,
        classId: classId
      }
    },
    label: <>
        <LineChartOutlined /> {item.name}
      </>
  }));
  const classLinks = [{
    href: {
      pathname: '/reports/class/overview',
      query: {
        id: classId
      }
    },
    label: <span className="data-menu-link">
        <LineChartOutlined /> Class Dashboard
      </span>
  }];
  const onClickHandler = () => setIsOpen(false);
  return <>
      <ACLinksMenu onClick={onClickHandler} title="Class" links={classLinks} data-sentry-element="ACLinksMenu" data-sentry-source-file="ClassMenu.jsx" />
      <ACLinksMenu onClick={onClickHandler} title="Students" links={studentLinks} data-sentry-element="ACLinksMenu" data-sentry-source-file="ClassMenu.jsx" />
    </>;
}