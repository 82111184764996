import { Alert, Button, Checkbox, Empty, Input, Select, Spin, Tabs } from 'antd';
import lodash from 'lodash';
const {
  capitalize,
  chain,
  groupBy,
  uniqBy
} = lodash; // tree-shaking(?) bugs `chain`
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Results from './Results';
import ErrorMessage from '../../ErrorMessage';
import LabeledInput from '../../ui/LabeledInput';
import { useQuery } from '../../../lib/hooks';
import UiContext from '../../../lib/UiContext';
import { UI_REPORTS_SEARCH_QUERY } from '../../../helpers/gqlQueries/ui/search';
export const typeOptions = ['User', 'Class', 'School', 'District'];
const sortByOptions = {
  Relevance: (a, b) => 0,
  // don't reorder, API should return sorted this way
  Name: (a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
};
const getClasses = datum => datum.classes?.filter(c => c.current) ?? [];
const getSchools = datum => uniqBy([...getClasses(datum).map(c => c.school), ...(datum.schools ?? []), datum.school].filter(Boolean), 'id');
const getDistricts = datum => uniqBy([...getSchools(datum).map(s => s.district), datum.district].filter(Boolean), 'id');
const getStates = datum => uniqBy([...getDistricts(datum).map(d => d.state), datum.state].filter(Boolean), 'id');
const optionsSorter = e => [e.disabled, e.label.toLowerCase()];
const limit = 1000;
const Search = ({
  query,
  type
}) => {
  const SEMESTERS = useContext(UiContext).semesters.map(s => s.name);
  const {
    loading,
    error,
    data
  } = useQuery(UI_REPORTS_SEARCH_QUERY, {
    variables: {
      query,
      type,
      limit
    },
    skip: !(query && type)
  });
  const searchResults = useMemo(() => data?.search.map(datum => ({
    ...datum,
    stateIDs: getStates(datum).map(e => e.id),
    districtIDs: getDistricts(datum).map(e => e.id),
    schoolIDs: getSchools(datum).map(e => e.id)
  })) ?? [], [data]);
  const stateFilterOptions = useMemo(() => chain(data?.search ?? []).flatMap(getStates).uniqBy('id').map(e => ({
    value: e.id,
    label: e.name
  })).sortBy(optionsSorter).value(), [data]);
  const [stateID, setStateID] = useState('');
  const districtFilterOptions = useMemo(() => chain(data?.search ?? []).flatMap(getDistricts).uniqBy('id').map(e => ({
    value: e.id,
    label: `${e.name}, ${e.state.abbreviation}`,
    disabled: stateID && stateID !== e.state.id
  })).sortBy(optionsSorter).value(), [stateID, data]);
  const [districtID, setDistrictID] = useState('');
  useEffect(() => {
    const currentOption = districtFilterOptions.find(e => e.value === districtID);
    if (!currentOption || currentOption.disabled) {
      setDistrictID('');
    }
  }, [districtFilterOptions, districtID, setDistrictID]);
  const schoolFilterOptions = useMemo(() => chain(data?.search ?? []).flatMap(getSchools).uniqBy('id').map(e => ({
    value: e.id,
    label: e.name,
    disabled: stateID && stateID !== e.district?.state.id || districtID && districtID !== e.district?.id
  })).sortBy(optionsSorter).value(), [districtID, stateID, data]);
  const [schoolID, setSchoolID] = useState();
  useEffect(() => {
    const currentOption = schoolFilterOptions.find(e => e.value === schoolID);
    if (!currentOption || currentOption.disabled) {
      setSchoolID('');
    }
  }, [schoolFilterOptions, schoolID, setSchoolID]);
  const semesterOptions = useMemo(() => chain(data?.search ?? []).groupBy('semester').map((classes, semester) => ({
    value: semester,
    label: semester,
    current: classes.some(c => c.current)
  })).uniqBy('value').filter(({
    value
  }) => SEMESTERS.includes(value)).sortBy(e => SEMESTERS.indexOf(e.value)).value(), [data]);
  const defaultSemesters = useMemo(() => semesterOptions.length > 0 ? semesterOptions.filter(so => so.current).map(so => so.value) : null, [semesterOptions]);
  const [semesters, setSemesters] = useState(defaultSemesters);
  const [studentID, setStudentID] = useState('');
  const [email, setEmail] = useState('');
  const filteredResults = useMemo(() => searchResults.filter(r => !stateID || r.stateIDs.includes(stateID)).filter(r => !districtID || r.districtIDs.includes(districtID)).filter(r => !schoolID || r.schoolIDs.includes(schoolID)).filter(r => !semesters || semesters.includes(r.semester)).filter(r => (r.studentID ?? '').toLowerCase().includes(studentID.toLowerCase())).filter(r => (r.email ?? '').toLowerCase().includes(email.toLowerCase())), [searchResults, stateID, districtID, schoolID, semesters, studentID, email]);
  const [sort, setSort] = useState(Object.keys(sortByOptions)[0]);
  const sortedResults = useMemo(() => filteredResults.slice().sort(sortByOptions[sort]), [filteredResults, sort]);
  const [tab, setTab] = useState();
  const groupedResults = useMemo(() => [{
    key: `all:${sortedResults.map(r => r.id).join(',')}`,
    label: `All (${sortedResults.length})`,
    children: <Results results={sortedResults} />
  }, ...Object.entries(groupBy(sortedResults, 'type')).map(([k, results]) => ({
    id: k,
    key: `${k}:${results.map(r => r.id).join(',')}`,
    label: `${k.toLowerCase().split('_').map(capitalize).join(' ')} (${results.length})`,
    children: <Results results={results} />
  })).sort((a, b) => a.id < b.id ? -1 : 1)], [sortedResults]);
  useEffect(() => {
    const keys = groupedResults.map(g => g.key);
    if (!keys.includes(tab)) {
      setTab(keys.find(k => k.split(':')[0] === String(tab).split(':')[0]) ?? keys[0]);
    }
  }, [groupedResults, tab]);
  const resetFilters = useCallback(() => {
    setStateID('');
    setDistrictID('');
    setSchoolID('');
    setSemesters(defaultSemesters);
    setStudentID('');
    setEmail('');
    setTab();
  }, [defaultSemesters, setStateID, setDistrictID, setSchoolID, setSemesters, setStudentID, setEmail, setTab]);
  useEffect(resetFilters, [defaultSemesters, type, query]);
  if (loading) {
    return <Spin size="large" style={{
      display: 'block'
    }} />;
  }
  if (error) {
    return <ErrorMessage message="Failed to load search results." />;
  }
  return <>
			{data.search.length >= limit && <Alert showIcon type="info" message="Search displays only the first 1000 results. Try using a more specific search phrase or filters." style={{
      marginBottom: 10
    }} />}
			<div className="search-results-container">
				<div className="filter-container search-filter-aside">
					<LabeledInput label="Sort By" data-sentry-element="LabeledInput" data-sentry-source-file="index.jsx">
						<Select options={Object.keys(sortByOptions).map(k => ({
            label: k,
            value: k
          }))} value={sort} onChange={v => setSort(v)} data-sentry-element="Select" data-sentry-source-file="index.jsx" />
					</LabeledInput>
					{stateFilterOptions?.length > 0 && <LabeledInput label="State">
							<Select options={[{
            label: 'Any',
            value: ''
          }, ...stateFilterOptions]} value={stateID} onChange={setStateID} />
						</LabeledInput>}
					{districtFilterOptions?.length > 0 && <LabeledInput label="District">
							<Select options={[{
            label: 'Any',
            value: ''
          }, ...districtFilterOptions]} value={districtID} onChange={setDistrictID} />
						</LabeledInput>}
					{schoolFilterOptions?.length > 0 && <LabeledInput label="School">
							<Select options={[{
            label: 'Any',
            value: ''
          }, ...schoolFilterOptions]} value={schoolID} onChange={setSchoolID} />
						</LabeledInput>}
					{semesterOptions?.length > 0 && <LabeledInput label="Semester">
							<Checkbox.Group options={semesterOptions} value={semesters} onChange={setSemesters} />
						</LabeledInput>}
					{searchResults.some(d => d.studentID) && <LabeledInput label="Student ID">
							<Input value={studentID} onChange={e => setStudentID(e.target.value)} />
						</LabeledInput>}
					{searchResults.some(d => d.email) && <LabeledInput label="Email Adress">
							<Input value={email} onChange={e => setEmail(e.target.value)} />
						</LabeledInput>}
					<Button onClick={resetFilters} data-sentry-element="Button" data-sentry-source-file="index.jsx">Clear Filters</Button>
				</div>
				<div className="search-results" children={sortedResults.length === 0 ? <Empty description="No results found" /> : type === 'User' ? <Tabs type="card" items={groupedResults} activeKey={tab} onChange={setTab} /> : <Results results={sortedResults} />} />
			</div>
		</>;
};
export default Search;