// prettier-ignore
import { adminOnly, nonParent } from './access_defs';

export default {
  'toolkits/assignment-playlist': adminOnly,
  'toolkits/search-sim': adminOnly,
  'toolkits/search': nonParent,
  'toolkits/alignments/[slug]': nonParent,
  'toolkits/books/[slug]': nonParent,
  'toolkits/games/[slug]': nonParent,
  'toolkits/lessons/[slug]': nonParent,
  'toolkits/library/[slug]': nonParent,
  'toolkits/printables/[slug]': nonParent,
  'toolkits/units/[slug]': nonParent,
  'toolkits/videos/[slug]': nonParent,
  'toolkits/[[...path]]': nonParent,
};
