import { Menu } from 'antd';
import { FolderOutlined } from '@ant-design/icons';
import { sortBy } from 'lodash';
import { memo, useCallback } from 'react';
import breadcrumbFor from './breadcrumbFor';
import ErrorMessage from '../../../components/ErrorMessage';
import { LoadingMenu } from '../../../components/ui/LoadingCards';
import { UI_PROGRAMS_QUERY } from '../../../helpers/gqlQueries/ui/tree';
import { useQuery } from '../../../lib/hooks';
const ProgramsMenu = ({
  setIsOpen,
  updateState
}) => {
  const {
    loading,
    error,
    data
  } = useQuery(UI_PROGRAMS_QUERY);
  const addProgramBreadcrumb = useCallback(e => updateState(breadcrumbFor(data.programs.find(p => p.id === e.key))), [updateState, data]);
  if (loading) {
    return <LoadingMenu />;
  }
  if (error) {
    return <ErrorMessage message="Error loading data." />;
  }
  return <Menu onClick={addProgramBreadcrumb} items={sortBy(data.programs, p => p.name.toLowerCase()).map(({
    id,
    name
  }) => ({
    key: id,
    label: <>
							<FolderOutlined /> {name}
						</>
  }))} data-sentry-element="Menu" data-sentry-component="ProgramsMenu" data-sentry-source-file="ProgramsMenu.jsx" />;
};
export default memo(ProgramsMenu);