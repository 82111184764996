import { Menu } from 'antd';
import { FolderOutlined } from '@ant-design/icons';
import { sortBy } from 'lodash';
import breadcrumbFor from './breadcrumbFor';
import ErrorMessage from '../../../components/ErrorMessage';
import { LoadingMenu } from '../../../components/ui/LoadingCards';
import { UI_STATE_QUERY } from '../../../helpers/gqlQueries/ui/tree';
import { useQuery } from '../../../lib/hooks';
export default function StateMenu({
  stateId,
  updateState,
  setIsOpen
}) {
  const {
    loading,
    error,
    data
  } = useQuery(UI_STATE_QUERY, {
    variables: {
      stateId
    }
  });
  if (loading) {
    return <LoadingMenu />;
  }
  if (error) {
    return <ErrorMessage message="Error loading data." />;
  }
  const districts = sortBy(data.stateById.districts, d => d.name.toLowerCase());
  return <Menu onClick={e => updateState(breadcrumbFor(districts.find(d => d.id === e.key)))} items={[{
    type: 'group',
    key: 'districts',
    label: 'Districts',
    children: districts.map(district => ({
      key: district.id,
      label: <>
              <FolderOutlined /> {district.name}
            </>
    }))
  }]} data-sentry-element="Menu" data-sentry-component="StateMenu" data-sentry-source-file="StateMenu.jsx" />;
}