import { theme } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useContext } from 'react';
import * as EntityHeaders from './entityHeaders';
import DashboardTabs from './DashboardTabs';
import UiContext from '../../lib/UiContext';
const InfoPanel = ({
  setIsOpen
}) => {
  const style = {
    paddingBottom: 0,
    backgroundColor: theme.useToken().token.colorBgContainer
  };
  const uiContext = useContext(UiContext);
  if (!uiContext.entity) {
    return null;
  }
  const {
    menu,
    breadcrumbs = [],
    title,
    extra
  } = (uiContext.entity.type === 'STUDENT' ? EntityHeaders.Student : EntityHeaders[uiContext.entity.__typename])(uiContext);
  return <PageHeader breadcrumb={{
    separator: '/',
    routes: breadcrumbs.map((e, i) => ({
      breadcrumbName: i,
      label: e
    })),
    itemRender: route => <span className="pageheader-crumb">{route.label}</span>
  }} ghost={false} title={title} subTitle={<a onClick={() => setIsOpen(true)}>Change</a>} footer={<DashboardTabs menuData={menu} />} extra={extra} style={style} data-sentry-element="PageHeader" data-sentry-component="InfoPanel" data-sentry-source-file="InfoPanel.jsx" />;
};
export default InfoPanel;