// prettier-ignore
import { anyone } from './access_defs';

export default {
    'reports': anyone,
    'reports/search': anyone,

    'reports/program/leaderboards': (_, { id }) => `acl:programGroupById(id: ${id}){ reportAccess }`,
    'reports/program/overview': (_, { id }) => `acl:programGroupById(id: ${id}){ reportAccess }`,
    'reports/program/proficiency': (_, { id }) => `acl:programGroupById(id: ${id}){ reportAccess }`,
    'reports/program/growth': (_, { id }) => `acl:programGroupById(id: ${id}){ reportAccess }`,
    'reports/program/community': (_, { id }) => `acl:programGroupById(id: ${id}){ reportAccess }`,

    'reports/district/leaderboards': (_, { id }) => `acl:districtById(id: ${id}){ reportAccess }`,
    'reports/district/overview': (_, { id }) => `acl:districtById(id: ${id}){ reportAccess }`,
    'reports/district/growth': (_, { id }) => `districtById(id: ${id}){ districtSchools{ id } }`,
    'reports/district/proficiency': (_, { id }) => `districtById(id: ${id}){ districtSchools{ id } }`,
    'reports/district/community': (_, { id }) => `districtById(id: ${id}){ nonDistrictSchools{ id } }`,
    'reports/district/community-details': (_, { id }) =>`districtById(id: ${id}){ nonDistrictSchools{ id } }`,
    'reports/district/unassigned': (_, { id }) => `districtById(id: ${id}){ nonDistrictSchools{ id } }`,

    'reports/class/activities': (_, { id }) => `classById(id: ${id}){ id }`,
    'reports/class/attendance': (_, { id }) => `classById(id: ${id}){ id }`,
    'reports/class/assignments-gradebook': (_, { id }) => `classById(id: ${id}){ id }`,
    'reports/class/shared-links': (_, { id }) => `classById(id: ${id}){ id }`,
    'reports/class/proficiency': (_, { id }) => `classById(id: ${id}){ id }`,
    'reports/class/groups': (_, { id }) => `classById(id: ${id}){ id }`,
    'reports/class/growth': (_, { id }) => `classById(id: ${id}){ id }`,
    'reports/class/overview': (_, { id }) => `classById(id: ${id}){ id }`,
    'reports/class/usage': (_, { id }) => `classById(id: ${id}){ id }`,
    'reports/class/writing': (_, { id }) => `classById(id: ${id}){ id }`,

    'reports/school/growth': (_, { id }) => `acl:schoolById(id: ${id}){ reportAccess }`,
    'reports/school/leaderboard': (_, { id }) => `acl:schoolById(id: ${id}){ reportAccess }`,
    'reports/school/proficiency': (_, { id }) => `acl:schoolById(id: ${id}){ reportAccess }`,
    'reports/school/overview': (_, { id }) => `acl:schoolById(id: ${id}){ reportAccess }`,
    'reports/school/unassigned': (_, { id }) => `acl:schoolById(id: ${id}){ reportAccess }`,

    'reports/student/activity-log': (_, { id }) => `userById(id: ${id}){ id }`,
    'reports/student/assignments': (_, { id }) => `userById(id: ${id}){ id }`,
    'reports/student/shared-links': (_, { id }) => `userById(id: ${id}){ id }`,
    'reports/student/growth': (_, { id }) => `userById(id: ${id}){ id }`,
    'reports/student/overview': (_, { id }) => `userById(id: ${id}){ id }`,
    'reports/student/reading-log': (_, { id }) => `userById(id: ${id}){ id }`,
    'reports/student/usage': (_, { id }) => `userById(id: ${id}){ id }`,
    'reports/student/writing-log': (_, { id }) => `userById(id: ${id}){ id }`,
    'reports/student/proficiency': (_, { id }) => `userById(id: ${id}){ id }`,

    'reports/user/overview': (_, { id }) => `userById(id: ${id}){ id }`,
};
