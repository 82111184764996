import { ConfigProvider } from 'antd';
import 'graphiql/graphiql.css';
import '@graphiql/plugin-explorer/dist/style.css';
import '../../theme/reports.scss';
import '../../theme/graphs.scss';
import '../../theme/tooltip.scss';
import 'react-datepicker/dist/react-datepicker.css';
import '../../theme/react-datepicker.scss';
const Theme = ({
  children
}) => <ConfigProvider children={children} theme={{
  token: {
    //seed
    borderRadius: 4,
    colorPrimary: '#1EABDE',
    colorLink: '#1EABDE',
    colorError: '#f5222d',
    fontFamily: `
					Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial,
					'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
					'Noto Color Emoji'
				`,
    //map
    colorBorder: '#5ebcdf',
    colorText: 'rgba(0, 0, 0, 0.65)',
    colorTextSecondary: '#1EABDE',
    fontSizeHeading4: Math.ceil(14 * 1.22),
    //alias
    colorTextHeading: 'rgba(0, 0, 0, 0.85)',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)'
  },
  components: {
    Button: {
      defaultBorderColor: '#5ebcdf',
      // colorBorder
      defaultColor: '#1EABDE' // colorPrimary
    },
    Menu: {
      itemHeight: 30
    },
    Badge: {
      textFontSize: 8,
      textFontSizeSM: 8
    }
  }
}} data-sentry-element="ConfigProvider" data-sentry-component="Theme" data-sentry-source-file="Theme.jsx" />;
export default Theme;