import { createElement, useEffect } from 'react';
import { useRerender } from '../lib/hooks';
import ErrorMessage from './ErrorMessage';
import LoadingCards from './ui/LoadingCards';
const cache = {};
const cmsFactory = (component, hardDataUrl) => ({
  dataURL: softDataURL,
  ...compProps
}) => {
  const dataURL = hardDataUrl ?? softDataURL;
  const rerender = useRerender();
  const loading = !cache[dataURL];
  const {
    data,
    error
  } = cache[dataURL] ?? {};
  useEffect(() => {
    !cache[dataURL]?.data && fetch(dataURL, {
      cache: 'no-store',
      credentials: 'omit',
      mode: 'same-origin',
      redirect: 'error',
      priority: 'high'
    }).then(r => {
      if (r.ok) {
        return r.json();
      }
      throw new Error('Response not successful', {
        cause: r
      });
    }).then(data => {
      cache[dataURL] = {
        data
      };
    }).catch(error => {
      cache[dataURL] = {
        error
      };
    }).finally(rerender);
  }, [dataURL]);
  if (loading) {
    return <LoadingCards />;
  }
  if (error) {
    console.error(error);
    return <ErrorMessage message="Failed to load the page." />;
  }
  return createElement(component, {
    ...compProps,
    data,
    key: dataURL
  });
};
export default cmsFactory;