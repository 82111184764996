import fetch from 'isomorphic-unfetch';
import Cookies from 'js-cookie';
import withApollo from 'next-with-apollo';
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import pLimit from 'p-limit';
import getConfig from 'next/config';

const complexLimit = pLimit(5);
const metricLimit = pLimit(5);
const uiLimit = pLimit(1);
const acLimit = pLimit(1);

const { publicRuntimeConfig = {} } = getConfig() || {};
const serverUri = publicRuntimeConfig.graphqlURL;

const loggerLink = new ApolloLink((operation, forward) => {
  const profile = process.env.NODE_ENV === 'development' ? true : false;
  if (profile) {
    operation.setContext({ start: new Date() });
    return forward(operation).map((response) => {
      const responseTime = new Date() - operation.getContext().start;
      const roundedResponseTime = (responseTime / 1000).toFixed(1);
      console.log(`${operation.operationName}: ${roundedResponseTime}s`);
      return response;
    });
  }
  return forward(operation);
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = Cookies.get('sso_jwt');
  // return the headers to the context so httpLink can read them
  // console.log('token:', token);
  return {
    headers: {
      ...headers,
      Authorization: token ? `bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors?.find(({ message }) => message === 'Authentication required')) {
    const params = new URLSearchParams();
    params.set('return_url', window.location.href);
    window.location = `${process.env.loginURL}?${params.toString()}`;
  }
});

const retryLink = new RetryLink({
  delay: {
    initial: 3000,
    max: 10000,
    jitter: true,
  },
  attempts: {
    max: 5,
    // eslint-disable-next-line no-unused-vars
    retryIf: (error, _operation) => error && error.message !== 'access denied',
  },
});

const customErrorLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    if (data && data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0].message);
    }
    return data;
  });
});

const createHttpLink = () => {
  const createBatchHttpLink = (fetchLimit, batchMax, batchInterval) =>
    new BatchHttpLink({
      fetch: (uri, options) => fetchLimit(fetch, uri, options),
      uri: serverUri,
      credentials: 'include',
      batchMax,
      batchInterval,
    });

  const complexLink = createBatchHttpLink(complexLimit, 1, 0);
  const metricLink = createBatchHttpLink(metricLimit, 1, 500);
  const accessControlLink = createBatchHttpLink(acLimit, 50, 500);
  const uiLink = createBatchHttpLink(uiLimit, 10, 500);

  return ApolloLink.split(
    (operation) => operation.operationName.startsWith('COMPLEX'),
    complexLink,
    ApolloLink.split(
      (operation) => operation.operationName.startsWith('METRIC'),
      metricLink,
      ApolloLink.split(
        (operation) => operation.operationName.startsWith('ACCESS_CONTROL'),
        accessControlLink,
        uiLink
      )
    )
  );
};

const httpLink = createHttpLink();

export default withApollo(({ initialState }) => {
  const ssrMode = typeof window === 'undefined';

  return new ApolloClient({
    ssrMode,
    link: ApolloLink.from([loggerLink, authLink, retryLink, customErrorLink, errorLink, httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        AuthUser: {
          keyFields: ['user', ['id']],
        },
      },
    }).restore(initialState || {}),
    connectToDevTools: true,
    // This does not work for now https://github.com/apollographql/apollo-client/issues/3717 https://gitmemory.com/issue/trojanowski/react-apollo-hooks/76/461642109
    // defaultOptions: { watchQuery: { ssr: false } },
  });
});

export function getClient() {
  // return ApolloLink.from([loggerLink, authLink, errorLink, retryLink, customErrorLink, httpLink]);
  return ApolloLink.from([authLink, errorLink, httpLink]);
}
