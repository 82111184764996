import { Tag } from 'antd';
const SchoolInfoHeader = uiContext => {
  const queryParams = {
    id: uiContext.entity.id,
    startDate: uiContext.dateSpan.from.format('YYYY-MM-DD'),
    endDate: uiContext.dateSpan.to.format('YYYY-MM-DD')
  };
  const menu = [{
    key: 'overview',
    title: 'Overview',
    icon: false,
    link: {
      pathname: `/reports/school/overview`,
      query: queryParams
    }
  }, {
    key: 'leaderboard',
    title: 'Leaderboard',
    icon: true,
    children: [{
      key: 'classes',
      title: 'Classes',
      icon: false,
      link: {
        pathname: `/reports/school/leaderboard`,
        query: {
          ...queryParams,
          view: `classes`
        }
      }
    }, {
      key: 'students',
      title: 'Students',
      icon: false,
      link: {
        pathname: `/reports/school/leaderboard`,
        query: {
          ...queryParams,
          view: `students`
        }
      }
    }]
  }, {
    key: 'proficiency',
    title: 'Proficiency',
    icon: false,
    link: {
      pathname: `/reports/school/proficiency`,
      query: queryParams
    }
  }, {
    key: 'growth',
    title: 'Growth',
    icon: false,
    link: {
      pathname: `/reports/school/growth`,
      query: queryParams
    }
  }];
  const breadcrumbs = [<>
      {uiContext.entity.district.name}, {uiContext.entity.district.state.abbreviation}
      {uiContext.entity.district.isMic && <Tag color="green">MIC</Tag>}
    </>];
  const title = [`${uiContext.entity.type?.split('_').map(w => `${w[0]}${w.slice(1).toLowerCase()}`).join(' ') || uiContext.entity.__typename} School: ${uiContext.entity.name}`];
  return {
    menu,
    breadcrumbs,
    title
  };
};
export default SchoolInfoHeader;