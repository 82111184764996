import { CaretDownOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import AC from '../AccessControl';
const QuickLinks = ({
  title,
  type,
  id,
  dateSpan,
  display = 'list'
}) => {
  const queryParams = {
    id: id,
    startDate: dateSpan.from,
    endDate: dateSpan.to
  };
  const data = {
    class: [{
      label: 'Overview',
      pathname: `/reports/class/overview`,
      query: queryParams
    }, {
      label: 'Attendance',
      pathname: `/reports/class/attendance`,
      query: queryParams
    }, {
      label: 'Assignments',
      pathname: '/reports/class/assignments-gradebook',
      query: queryParams
    }, {
      label: 'Class Activity Log',
      pathname: '/reports/class/activities',
      query: queryParams
    }, {
      label: 'Writing',
      pathname: '/reports/class/writing',
      query: queryParams
    }, {
      label: 'Flexible Groups',
      pathname: '/reports/class/proficiency',
      query: {
        ...queryParams,
        tab: 'performance'
      }
    }, {
      label: 'Areas of Difficulty',
      pathname: '/reports/class/proficiency',
      query: {
        ...queryParams,
        tab: 'difficulty'
      }
    }],
    school: [{
      label: 'Overview',
      pathname: `/reports/school/overview`,
      query: queryParams
    }, {
      label: 'Top Classes',
      pathname: '/reports/school/leaderboard',
      query: {
        ...queryParams,
        view: 'classes'
      }
    }, {
      label: 'Top Students',
      pathname: '/reports/school/leaderboard',
      query: {
        ...queryParams,
        view: 'students'
      }
    }, {
      label: 'Proficiency',
      pathname: '/reports/school/proficiency',
      query: queryParams
    }, {
      label: 'Growth',
      pathname: '/reports/school/growth',
      query: queryParams
    }],
    district: [{
      label: 'Overview',
      pathname: `/reports/district/overview`,
      query: queryParams
    }, {
      label: 'Top Schools',
      pathname: '/reports/district/leaderboards',
      query: {
        ...queryParams,
        view: 'schools'
      }
    }, {
      label: 'Top Classes',
      pathname: '/reports/district/leaderboards',
      query: {
        ...queryParams,
        view: 'classes'
      }
    }, {
      label: 'Top Students',
      pathname: '/reports/district/leaderboards',
      query: {
        ...queryParams,
        view: 'students'
      }
    }, {
      label: 'Proficiency',
      pathname: '/reports/district/proficiency',
      query: queryParams
    }, {
      label: 'Growth',
      pathname: '/reports/district/growth',
      query: queryParams
    }],
    program: [{
      label: 'Overview',
      pathname: `/reports/program/overview`,
      query: queryParams
    }, {
      label: 'Top Districts',
      pathname: '/reports/program/leaderboards',
      query: {
        ...queryParams,
        view: 'districts'
      }
    }, {
      label: 'Top Schools',
      pathname: '/reports/program/leaderboards',
      query: {
        ...queryParams,
        view: 'schools'
      }
    }, {
      label: 'Top Classes',
      pathname: '/reports/program/leaderboards',
      query: {
        ...queryParams,
        view: 'classes'
      }
    }, {
      label: 'Top Students',
      pathname: '/reports/program/leaderboards',
      query: {
        ...queryParams,
        view: 'students'
      }
    }, {
      label: 'Proficiency',
      pathname: '/reports/program/proficiency',
      query: queryParams
    }, {
      label: 'Growth',
      pathname: '/reports/program/growth',
      query: queryParams
    }]
  };
  return !data[type] ? null : display == 'dropdown' ? <Dropdown menu={{
    items: data[type].map(link => ({
      key: link.label,
      label: <AC.Link href={{
        pathname: link.pathname,
        query: link.query
      }} label={link.label} />
    }))
  }}>
        <a onClick={e => e.preventDefault()}>{title} <CaretDownOutlined /></a>
      </Dropdown> : <div className="link-list">
        <h4>Quick Links</h4>
        {data[type].map(link => <span className="vertical-list-items" key={link.label}>
            <AC.Link href={{
        pathname: link.pathname,
        query: link.query
      }} label={link.label} />
          </span>)}
      </div>;
};
export default QuickLinks;