const LabeledInput = ({
  label,
  extra,
  children
}) => <div className="label-input-container" data-sentry-component="LabeledInput" data-sentry-source-file="LabeledInput.jsx">
		<div className="label-container">
			<span className="lable-text">{label}</span>
			<span>{extra}</span>
		</div>
		<div className="input-container">{children}</div>
	</div>;
export default LabeledInput;