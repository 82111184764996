import styles from './FeatureCard.module.scss';
import AC from '../AccessControl';
const FeatureCard = ({
  image,
  title,
  desc,
  url,
  size = "default"
}) => <div className={styles["featured-card-container"]} data-sentry-component="FeatureCard" data-sentry-source-file="FeatureCard.jsx">
    <div className={`${styles['featured-image']} ${styles[size]}`}>
      <AC.Link href={url} label={image} data-sentry-element="unknown" data-sentry-source-file="FeatureCard.jsx" />
    </div>
    <div className={styles["featured-title"]}>{title}</div>
    <div className={styles["featured-description"]}>{desc}</div>
  </div>;
export default FeatureCard;