import dayjs from 'dayjs';
import Model from './Model';
export default class School extends Model {
  constructor(entityData) {
    if (entityData.__typename !== 'School') {
      throw new Error('invalid data supplied');
    }
    super(entityData);
    this.__sortedSemesters = [...this.semestersV2].sort((a, b) => a.order - b.order);
  }
  get defaultDateRange() {
    const from = dayjs(this.__sortedSemesters[0].earliestDate, 'YYYY-MM-DD');
    const to = dayjs(this.__sortedSemesters[0].latestDate, 'YYYY-MM-DD');
    return this.adjustDateRange({
      from,
      to
    });
  }
  get possibleDateRange() {
    const from = dayjs(this.district.startDate, 'YYYY-MM-DD');
    const to = dayjs(this.__sortedSemesters[0].latestDate, 'YYYY-MM-DD');
    return {
      // use the oldest date
      from: from.isBefore(super.possibleDateRange.from) ? from : super.possibleDateRange.from,
      to: to.isAfter(super.possibleDateRange.to) ? super.possibleDateRange.to : to
    };
  }
  get quickDateRanges() {
    const ranges = [30, 60].map(n => ({
      label: `Last ${n} Days`,
      value: {
        from: dayjs().subtract(n + 1, 'days'),
        to: dayjs().subtract(1, 'days')
      }
    })).concat(this.__sortedSemesters.slice(0, 2).map(s => ({
      label: s.name,
      value: {
        from: dayjs(s.earliestDate, 'YYYY-MM-DD'),
        to: dayjs(s.latestDate, 'YYYY-MM-DD')
      }
    })));
    ranges.forEach(r => r.value = this.adjustDateRange(r.value));
    return ranges;
  }
}