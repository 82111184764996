import gql from 'graphql-tag';
export const UI_GET_SEMESTERS = gql`
  query UI_GET_SEMESTERS {
    semesters {
      name
      order
      classCount
      earliestDate
      latestDate
    }
  }
`;
export const UI_GET_APPS = gql`
  query UI_GET_APPS {
    apps {
      id
      name
      longName
      subname
      accessible
      iconURL
      url
      grades {
        id
        name
        position
      }
    }
  }
`;
export const UI_GET_GRADE_LIST = gql`
  query UI_GET_GRADE_LIST {
    grades {
      position
      name
      id
    }
  }
`;
export const UI_GET_GRADE_LEVEL_LIST = gql`
  query UI_GET_GRADE_LIST {
    gradeLevels {
      position
      name
      id
    }
  }
`;
export const UI_GET_AGE_GROUPS = gql`
  query UI_GET_AGE_GROUPS {
    ageGroups {
      id
      name
      from
      to
    }
  }
`;