import { useContext } from 'react';
import ErrorMessage from '../components/ErrorMessage';
import LoadingCards from '../components/ui/LoadingCards';
import UiContext from '../lib/UiContext';
const ContextLoadingChecker = ({
  children
}) => {
  const uiContext = useContext(UiContext);
  if (uiContext.loading) {
    return <LoadingCards />;
  }
  if (uiContext.error) {
    return <ErrorMessage message="There was an error during loading basic data. Reload the page to try again." />;
  }
  return children;
};
export default ContextLoadingChecker;