const ProgramInfoInfoHeader = uiContext => {
  const queryParams = {
    id: uiContext.entity.id,
    startDate: uiContext.dateSpan.from.format('YYYY-MM-DD'),
    endDate: uiContext.dateSpan.to.format('YYYY-MM-DD')
  };
  const menu = [{
    key: 'overview',
    title: 'Overview',
    icon: false,
    link: {
      pathname: `/reports/program/overview`,
      query: queryParams
    }
  }, {
    key: 'leaderboards',
    title: 'Leaderboard',
    icon: true,
    children: [{
      key: 'districts',
      title: 'Districts',
      icon: false,
      link: {
        pathname: `/reports/program/leaderboards`,
        query: {
          ...queryParams,
          view: `districts`
        }
      }
    }, {
      key: 'schools',
      title: 'Schools',
      icon: false,
      link: {
        pathname: `/reports/program/leaderboards`,
        query: {
          ...queryParams,
          view: `schools`
        }
      }
    }, {
      key: 'classes',
      title: 'Classes',
      icon: false,
      link: {
        pathname: `/reports/program/leaderboards`,
        query: {
          ...queryParams,
          view: `classes`
        }
      }
    }, {
      key: 'students',
      title: 'Students',
      icon: false,
      link: {
        pathname: `/reports/program/leaderboards`,
        query: {
          ...queryParams,
          view: `students`
        }
      }
    }]
  }, {
    key: 'proficiency',
    title: 'Proficiency',
    icon: false,
    link: {
      pathname: `/reports/program/proficiency`,
      query: queryParams
    }
  }, {
    key: 'growth',
    title: 'Growth',
    icon: false,
    link: {
      pathname: `/reports/program/growth`,
      query: queryParams
    }
  }, uiContext.entity.hasMic && {
    key: 'community',
    title: 'Community Summary',
    icon: false,
    link: {
      pathname: `/reports/program/community`,
      query: queryParams
    }
  }].filter(Boolean);
  const title = <>
      Program: {uiContext.entity.name}
    </>;
  return {
    menu,
    title
  };
};
export default ProgramInfoInfoHeader;