import { useQuery as apolloUseQuery, useLazyQuery as apolloUseLazyQuery } from '@apollo/client';
export { useApolloClient, useMutation } from '@apollo/client';
export const useQuery = (query, options) => {
  return apolloUseQuery(query, {
    ...options,
    ssr: false
  });
};
export const useLazyQuery = (query, options) => {
  return apolloUseLazyQuery(query, {
    ...options,
    ssr: false
  });
};