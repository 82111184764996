import gql from 'graphql-tag';
export const UI_REPORTS_SEARCH_QUERY = gql`
	query UI_REPORTS_SEARCH_QUERY($query: String!, $type: SearchType!, $limit: Int!) {
		search(contains: $query, types: [$type], limit: $limit){
			...DistrictFields,
			...SchoolFields
			...ClassFields
			...UserFields
		}
	}
	fragment StateFields on State {
		id
		name
		abbreviation
	}
	fragment DistrictFields on District {
		id,
		name,
		state { ...StateFields }
	}
	fragment SchoolFields on School {
		id
		name
		district { ...DistrictFields }
	}
	fragment ClassFields on Class {
		id
		name
		semester
		current
		school { ...SchoolFields }
	}
	fragment UserFields on User {
		id
		name
		type
		studentID
		email
		classes { ...ClassFields }
		schools { ...SchoolFields }
		district { ...DistrictFields }
	}
`;