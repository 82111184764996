import { Layout } from 'antd';
import { useRouter } from 'next/router';
import Default, * as subLayouts from '../../layouts';
const MainLayout = ({
  subLayout,
  children
}) => {
  const router = useRouter();
  if (router.pathname.split('/').filter(Boolean).join('/') === 'gql') {
    return children;
  }
  const SubLayout = (() => {
    if ([null, undefined].includes(subLayout)) {
      return subLayouts[router.pathname.split('/').filter(Boolean)[0]] ?? Default;
    }
    if (subLayouts[subLayout]) {
      return subLayouts[subLayout];
    }
    throw new Error(`Undefined subLayout: ${subLayout}`);
  })();
  return <Layout style={{
    minHeight: '100vh'
  }} data-sentry-element="Layout" data-sentry-component="MainLayout" data-sentry-source-file="MainLayout.jsx">
      <SubLayout data-sentry-element="SubLayout" data-sentry-source-file="MainLayout.jsx">
        {children}
      </SubLayout>
      <Layout.Footer data-sentry-element="unknown" data-sentry-source-file="MainLayout.jsx">
        <div className="landing-footer-container no-print">
          <div className="landing-footer landing-footer-left">
            <p>
              Need Help? <br />
              Contact Support:{' '}
              <a href="mailto:support@footsteps2Brilliance.com">
                support@footsteps2Brilliance.com
              </a>
            </p>
          </div>
          <div className="landing-footer landing-footer-right">
            <ul className='footer-link-list'>
            {process.env.footerLinks.map(({
              href,
              title
            }) => <li key={href}>
                  <a href={href}>{title}</a>
                </li>)}
            </ul>
          </div>
        </div>
        <div className="copyright">
          &copy;{new Date().getFullYear()}, Footsteps2Brilliance, Inc. <span className="build-number">({process.env.NEXT_PUBLIC_BUILD_NUMBER})</span>
        </div>
      </Layout.Footer>
    </Layout>;
};
export default MainLayout;