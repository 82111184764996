import ClassAvatar from '../../../components/images/ClassAvatar';

const breadcrumbFor = ({ id, name, __typename: type, ...rest }) => ({
	id: id,
	name: name,
	semesters: type === 'Class' ? [rest.semester] : (
		type === 'School' ? rest.semestersV2.map(s => s.name) : null,
	),
	type: type === 'ProgramGroup' ? 'Program' : type,
	icon: type === 'Class' ? <ClassAvatar of={rest} size="1.5em" /> : null,
});

export default breadcrumbFor;
