import { Menu } from 'antd';
import { useState } from 'react';
import AC from '../../../components/AccessControl';
const ACLinksMenu = ({
  onClick,
  title,
  links
}) => {
  const [linksAC, setLinksAC] = useState(links.map(() => true));
  const updateLinksAC = (index, val) => {
    if (linksAC[index] !== val) {
      const newArr = [...linksAC];
      newArr[index] = val;
      setLinksAC(newArr);
    }
  };
  if (!linksAC.includes(true)) {
    return null;
  }
  return <Menu onClick={onClick} items={[{
    type: 'group',
    key: 'links',
    label: title,
    children: links.map((link, index) => ({
      key: `link-${index}`,
      label: <AC.Link {...link} onChecked={val => updateLinksAC(index, val)} />
    }))
  }]} data-sentry-element="Menu" data-sentry-component="ACLinksMenu" data-sentry-source-file="ACLinksMenu.jsx" />;
};
export default ACLinksMenu;