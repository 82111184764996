import { Menu } from 'antd';
import { LineChartOutlined } from '@ant-design/icons';
import { capitalize, groupBy, sortBy } from 'lodash';
import ACLinksMenu from './ACLinksMenu';
import breadcrumbFor from './breadcrumbFor';
import ErrorMessage from '../../../components/ErrorMessage';
import ClassAvatar from '../../../components/images/ClassAvatar';
import { LoadingMenu } from '../../../components/ui/LoadingCards';
import { UI_SCHOOL_QUERY } from '../../../helpers/gqlQueries/ui/tree';
import { useQuery } from '../../../lib/hooks';
export default function SchoolMenu({
  schoolId,
  semester,
  updateState,
  hideReports,
  setIsOpen
}) {
  const {
    loading,
    error,
    data
  } = useQuery(UI_SCHOOL_QUERY, {
    variables: {
      schoolId,
      semester
    }
  });
  if (loading) {
    return <LoadingMenu />;
  }
  if (error) {
    return <ErrorMessage message="Error loading data." />;
  }
  const links = [{
    href: {
      pathname: '/reports/school/overview',
      query: {
        id: schoolId
      }
    },
    label: <span className="data-menu-link">
        <LineChartOutlined /> School Dashboard
      </span>
  }];
  const typeOrder = ['HOMEROOM', 'FLEXIBLE'];
  const classesByType = groupBy(sortBy(data.schoolById.classes.filter(v => !v.phantomClass), c => [typeOrder.indexOf(c.type), c.name.toLowerCase()]), 'type');
  return <>
      <ACLinksMenu onClick={() => setIsOpen(false)} title="School" links={links} data-sentry-element="ACLinksMenu" data-sentry-source-file="SchoolMenu.jsx" />
      <Menu onClick={e => updateState(breadcrumbFor(data.schoolById.classes.find(c => c.id === e.key)))} items={Object.entries(classesByType).map(([type, classes]) => ({
      type: 'group',
      key: type,
      label: `${capitalize(type)} Classes`,
      children: classes.map(sc => ({
        key: sc.id,
        style: {
          height: 'auto'
        },
        label: <div style={{
          lineHeight: '1.5em'
        }}>
                  <div style={{
            display: 'flex',
            gap: 4
          }}>
                    <ClassAvatar of={sc} size="1.5em" /> {sc.name}
                  </div>
                  <aside style={{
            display: 'flex',
            justifyContent: 'start',
            gap: 20
          }}>
                    <small style={{
              color: '#8C8C8C',
              fontWeight: 'normal'
            }}>
                      Semester: {sc.semester}
                    </small>
                    <small style={{
              color: '#8C8C8C',
              fontWeight: 'normal'
            }}>
                      Grade: {sc.grade}
                    </small>
                  </aside>
                </div>
      }))
    }))} data-sentry-element="Menu" data-sentry-source-file="SchoolMenu.jsx" />
    </>;
}