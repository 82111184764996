import FeatureCard from '../../panels/FeatureCard';
import CustomButton from '../../ui/CustomButton';
import htmlStringToReactNodes from '../../../helpers/htmlStringToReactNodes';
const FeaturedGrid = ({
  cards,
  buttons,
  numberOfColumns
}) => <div style={{
  display: 'grid',
  gap: 20,
  gridTemplateColumns: `repeat(${numberOfColumns}, 1fr)`
}} data-sentry-component="FeaturedGrid" data-sentry-source-file="FeaturedGrid.jsx">
	{cards.map(({
    title,
    showTitle,
    shortDescriptionHTML,
    size,
    page: {
      type,
      slug,
      name,
      imageURL
    }
  }) => <div key={slug}>
				<FeatureCard title={showTitle ? title : name} desc={htmlStringToReactNodes(shortDescriptionHTML)} url={{
      pathname: `/toolkits/${type}/[slug]`,
      query: {
        slug
      }
    }} size={size} image={<img src={imageURL} alt={name} />} />
			</div>)}
	{buttons.map(button => <div key={button.page.slug}>
				<CustomButton {...button} title={button.title || button.page.name || button.name} url={{
      pathname: `/toolkits/${button.page.type}/[slug]`,
      query: {
        slug: button.page.slug
      }
    }} />
			</div>)}
	</div>;
export default FeaturedGrid;