import { Affix, Layout } from 'antd';
import AppHeader from '../AppHeader';
import ContextLoadingChecker from '../ContextLoadingChecker';
import ToolkitNavBar from './ToolkitNavBar';
import { ToolkitsIcon } from '../../components/images/NavIcons';
const currentSection = {
  icon: <ToolkitsIcon />,
  name: 'Teacher Toolkits'
};
export default (({
  children
}) => <>
		<Affix>
			<AppHeader currentSection={currentSection} />
			<ToolkitNavBar />
		</Affix>
		<ContextLoadingChecker>
			<Layout.Content style={{
      margin: 20,
      marginTop: 0,
      overflow: 'initial'
    }} children={children} />
		</ContextLoadingChecker>
	</>);