import { Card, Skeleton, Spin } from 'antd';
import React from 'react';
export const LoadingAchievementCard = () => {
  return <Card data-sentry-element="Card" data-sentry-component="LoadingAchievementCard" data-sentry-source-file="LoadingCards.jsx">
      <Skeleton active avatar paragraph={{
      rows: 1
    }} data-sentry-element="Skeleton" data-sentry-source-file="LoadingCards.jsx" />
    </Card>;
};
export const LoadingMenu = () => {
  return <div className="menu-skeleton-contatiner" data-sentry-component="LoadingMenu" data-sentry-source-file="LoadingCards.jsx">
      <Skeleton paragraph={{
      rows: 4
    }} data-sentry-element="Skeleton" data-sentry-source-file="LoadingCards.jsx" />
    </div>;
};
const LoadingCards = () => {
  return <Card data-sentry-element="Card" data-sentry-component="LoadingCards" data-sentry-source-file="LoadingCards.jsx">
      <Skeleton active data-sentry-element="Skeleton" data-sentry-source-file="LoadingCards.jsx">
        <Spin data-sentry-element="Spin" data-sentry-source-file="LoadingCards.jsx" />
      </Skeleton>
    </Card>;
};
export default LoadingCards;