import { Menu } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { isEqual } from 'lodash';
import { useRouter } from 'next/router';
import { useState } from 'react';
import AC from '../../components/AccessControl';
const generateMenu = (menuArray, inaccessibleKeys, addInnacessibleKey) => menuArray.map(menu => {
  if (menu.hidden || inaccessibleKeys.includes(menu.key)) {
    return null;
  }
  if (menu.children) {
    const menuChildren = generateMenu(menu.children, inaccessibleKeys, addInnacessibleKey);
    if (menuChildren.length === 0) {
      return null;
    }
    return {
      ...menu,
      children: menuChildren,
      label: <>{menu.title} {menu.icon ? <CaretDownOutlined /> : null}</>
    };
  }
  return {
    ...menu,
    label: <AC.Link href={menu.link} label={menu.title} onChecked={isAccessible => !isAccessible && addInnacessibleKey(menu.key)} />
  };
}).filter(Boolean);
const selectedKeys = (menuData, router) => menuData.length > 0 ? [...selectedKeys(menuData.flatMap(e => e.children || []), router), ...menuData.filter(e => isEqual(e.link, router)).map(e => e.key)] : [];
const NavMenu = ({
  menuData
}) => {
  const {
    pathname,
    query
  } = useRouter();
  const [inaccessibleKeys, setInaccessibleKeys] = useState([]);
  const addInnacessibleKey = newKey => setInaccessibleKeys(prev => [...prev, newKey]);
  return <Menu mode="horizontal" selectedKeys={selectedKeys(menuData, {
    pathname,
    query
  })} items={generateMenu(menuData, inaccessibleKeys, addInnacessibleKey)} data-sentry-element="Menu" data-sentry-component="NavMenu" data-sentry-source-file="DashboardTabs.jsx" />;
};
const getAllKeys = items => items.map(e => e.key).concat(items.filter(e => e.children?.length > 0).flatMap(e => getAllKeys(e.children).map(k => `${e.key}->${k}`)));
const DashboardTabs = ({
  menuData
}) => <NavMenu menuData={menuData} key={getAllKeys(menuData).sort().join(':')} data-sentry-element="NavMenu" data-sentry-component="DashboardTabs" data-sentry-source-file="DashboardTabs.jsx" />;
export default DashboardTabs;