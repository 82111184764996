import * as humanizeDuration from 'humanize-duration';
import dayjs from 'dayjs';
export const DATE_FORMAT = 'MMM D[,] YYYY';
export const MONTH_FORMAT = 'MMM YYYY';
export const TIME_FORMAT = 'h:mm A z';
export const DATETIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'day',
      h: () => 'hr',
      m: () => 'min',
      s: () => 'sec',
      ms: () => 'ms'
    }
  },
  units: ['h', 'm', 's']
});
export const groupDatesBy = (startDate, endDate) => {
  const numberOfDays = dayjs(endDate, 'YYYY-MM-DD').diff(dayjs(startDate, 'YYYY-MM-DD'), 'days');
  if (numberOfDays >= 1095) {
    return {
      type: 'YEAR',
      label: 'Year',
      desription: 'Annually'
    };
  } else if (numberOfDays >= 180) {
    return {
      type: 'MONTH',
      label: 'Month',
      desription: 'Monthly'
    };
  } else if (numberOfDays >= 60) {
    return {
      type: 'WEEK',
      label: 'Week',
      desription: 'Weekly'
    };
  } else return {
    type: 'DAY',
    label: 'Day',
    desription: 'Daily'
  };
};
export const formatDatetime = (datetime, showTime = false) => !datetime ? null : (typeof datetime === 'string' ? dayjs.utc(datetime, ['YYYY-MM-DD', 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss.SSS'], true) : dayjs(datetime)).format(showTime ? DATETIME_FORMAT : DATE_FORMAT);
export const monthYearFormat = (datetime, grouping) => {
  if (grouping == 'MONTH') {
    return dayjs(formatDatetime(datetime)).format(MONTH_FORMAT);
  } else if (grouping == 'YEAR') {
    return dayjs(formatDatetime(datetime)).format('YYYY');
  } else return formatDatetime(datetime);
};

// export const formatDuration = seconds => {
//   if (seconds < 60) {
//     return `${seconds.toFixed(0)} sec`;
//   }

//   const hrsFloat = seconds / 3600;
//   const hrs = Math.floor(hrsFloat);
//   const mins = Math.round((hrsFloat - hrs) * 60);

//   const formatedMin = String(mins).padStart(2, '0');

//   return `${hrs} hr, ${formatedMin} min`;
// };

export const humanDuration = seconds => {
  const time = humanDurationString(seconds);
  return <time dateTime={`${seconds}s`} data-sentry-component="humanDuration" data-sentry-source-file="datetime.jsx">{time}</time>;
};
export const humanDurationString = seconds => {
  return shortEnglishHumanizer(seconds * 1000, {
    round: true,
    largest: 2,
    units: seconds < 60 * 5 ? ['h', 'm', 's'] : ['h', 'm']
  }).replace(/\B(?=(\d{3})+(?!\d))/g, ','); // this regex adds commas to large numbers
};
export const formatDuration = humanDuration;
export const formatDurationHours = hours => humanDuration(hours * 3600);
export const formatDateSpan = (start, end) => {
  if (start == dayjs().subtract(31, 'days').format('YYYY-MM-DD') && end == dayjs().subtract(1, 'days').format('YYYY-MM-DD')) {
    return `Last 30 Days`;
  } else if (start == dayjs().subtract(91, 'days').format('YYYY-MM-DD') && end == dayjs().subtract(1, 'days').format('YYYY-MM-DD')) {
    return `Last 90 Days`;
  } else {
    return <>
        {formatDatetime(start)} - {formatDatetime(end)}
      </>;
  }
};
export const lastNintyDays = {
  startDate: dayjs().subtract(91, 'days').format('YYYY-MM-DD'),
  endDate: dayjs().subtract(1, 'days').format('YYYY-MM-DD')
};