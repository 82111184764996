import { Menu } from 'antd';
import { FolderOutlined, LineChartOutlined } from '@ant-design/icons';
import { sortBy } from 'lodash';
import { memo, useCallback } from 'react';
import ACLinksMenu from './ACLinksMenu';
import breadcrumbFor from './breadcrumbFor';
import ErrorMessage from '../../../components/ErrorMessage';
import { LoadingMenu } from '../../../components/ui/LoadingCards';
import { UI_PROGRAM_QUERY } from '../../../helpers/gqlQueries/ui/tree';
import { useQuery } from '../../../lib/hooks';
const ProgramMenu = ({
  programId,
  setIsOpen,
  updateState
}) => {
  const {
    loading,
    error,
    data
  } = useQuery(UI_PROGRAM_QUERY, {
    variables: {
      programId
    }
  });
  const addDistrictBreadcrumb = useCallback(e => updateState(breadcrumbFor(data.programById.districts.find(d => d.id === e.key))), [updateState, data]);
  if (loading) {
    return <LoadingMenu />;
  }
  if (error) {
    return <ErrorMessage message="Error loading data." />;
  }
  const links = [{
    href: {
      pathname: '/reports/program/overview',
      query: {
        id: programId
      }
    },
    label: <span className="data-menu-link">
					<LineChartOutlined /> Program Dashboard
				</span>
  }];
  return <>
			<ACLinksMenu onClick={() => setIsOpen(false)} title="Program" links={links} data-sentry-element="ACLinksMenu" data-sentry-source-file="ProgramMenu.jsx" />
			<Menu onClick={addDistrictBreadcrumb} items={[{
      type: 'group',
      key: 'districts',
      label: 'Districts',
      children: sortBy(data.programById.districts, d => d.name.toLowerCase()).map(d => ({
        key: d.id,
        label: <>
								<FolderOutlined /> {d.name}
							</>
      }))
    }]} data-sentry-element="Menu" data-sentry-source-file="ProgramMenu.jsx" />
		</>;
};
export default memo(ProgramMenu);