import styles from './ToolkitNavBar.module.scss';
import cmsFactory from '../../components/cmsFactory';
import createComponent from '../../components/contentDisplay/dynamic';
import { createElement } from 'react';
const ToolkitNavBar = ({
  data: {
    leftMenu,
    rightMenu
  }
}) => <div className={styles["toolkit-nav-bar"]} data-sentry-component="ToolkitNavBar" data-sentry-source-file="ToolkitNavBar.jsx">
    <span children={leftMenu.map((item, key) => ({
    ...item,
    key
  })).map(createComponent)} />
    <span children={rightMenu.map((item, key) => ({
    ...item,
    key
  })).map(createComponent)} />
  </div>;
export default cmsFactory(ToolkitNavBar, '/content/singletons/navMenu.json');