import { html2json } from 'html2json';
import { decode } from 'html-entities';
import { Fragment, createElement } from 'react';
import AC from '../components/AccessControl';
const replaceCMSLinks = htmlString => htmlString?.replace(/\[([^\|]+)\|([^:]+):([^=]+)=([^\]]+)\s*\]/g, (_, linkTxt, type, __, slug) => {
  const [linkText, linkType, slugValue] = [linkTxt, type, slug].map(item => item.trim());
  const contentType = linkType === 'page' ? 'library' : `${linkType}s`;
  return `<ac-link pathname="${`/toolkits/${contentType}/[slug]`}" slug="${slugValue}">${linkText}</ac-link>`;
});
const htmlJsonToReactNodes = htmlJson => {
  if (!htmlJson || htmlJson.node === 'comment') {
    return null;
  }
  if (htmlJson.node === 'text') {
    return htmlJson.text;
  }
  if (htmlJson.tag === 'ac-link') {
    const {
      pathname,
      ...query
    } = htmlJson.attr;
    return createElement(AC.Link, {
      href: {
        pathname,
        query
      },
      label: htmlJson.child?.map(c => htmlJsonToReactNodes(c)).filter(Boolean)
    });
  }
  // rename html attributes to React ones, ignore style
  const attrs = Object.fromEntries(Object.entries((({
    class: className,
    for: htmlFor,
    style,
    ...rest
  }) => ({
    className,
    htmlFor,
    ...rest
  }))(htmlJson.attr ?? {})).filter(([k, v]) => v));
  const nodeType = htmlJson.node === 'root' ? Fragment : htmlJson.tag;
  const children = htmlJson.child?.map(c => htmlJsonToReactNodes(c)).filter(Boolean) ?? [];
  return createElement(nodeType, attrs, ...children);
};
const htmlStringToReactNodes = htmlString => htmlJsonToReactNodes(html2json(replaceCMSLinks(decode(htmlString))));
export default htmlStringToReactNodes;