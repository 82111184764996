import Search from './Search';
import CustomButton from '../../ui/CustomButton';
import { useSession } from '../../../lib/hooks';
import styles from './Banner.module.scss';
const Banner = ({
  header,
  subHeader,
  description,
  background = "#014ca1",
  customCSS,
  imageURL,
  imagePosition,
  action,
  search
}) => {
  const {
    name
  } = useSession().user;
  return <section className={styles["dynamic-banner-container"]} data-sentry-component="Banner" data-sentry-source-file="Banner.jsx">
			<div className={styles["welcome-message"]}>
				<span className={styles["welcome-text"]}>Welcome, {name}</span>
			</div>
			<div className={styles["background-banner"]} style={{
      background,
      ...customCSS
    }} />
			<div className={styles["banner-content"]}>
				<div className={styles["banner-header"]}>
					<header children={header} />
					<i children={subHeader} />
				</div>
				<div className={styles["banner-text"]} children={description} />
				{search && <Search {...search} size="large" />}
				{action && <CustomButton {...action} title={action.title || action.page.name || action.name} url={{
        pathname: `/toolkits/${action.page.type}/[slug]`,
        query: {
          slug: action.page.slug
        }
      }} />}
			</div>
			<div className={`${styles['hero-image']} ${styles[imagePosition]}`} children={imageURL && <img src={imageURL} alt="hero image" />} />
		</section>;
};
export default Banner;