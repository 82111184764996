import { Tag } from 'antd';
const DistrictInfoHeader = uiContext => {
  const queryParams = {
    id: uiContext.entity.id,
    startDate: uiContext.dateSpan.from.format('YYYY-MM-DD'),
    endDate: uiContext.dateSpan.to.format('YYYY-MM-DD')
  };
  const menu = [{
    key: 'overview',
    title: 'Overview',
    icon: false,
    link: {
      pathname: `/reports/district/overview`,
      query: queryParams
    }
  }, {
    key: 'leaderboards',
    title: 'Leaderboard',
    icon: true,
    children: [{
      key: 'schools',
      title: 'Schools',
      icon: false,
      link: {
        pathname: `/reports/district/leaderboards`,
        query: {
          ...queryParams,
          view: `schools`
        }
      }
    }, {
      key: 'classes',
      title: 'Classes',
      icon: false,
      link: {
        pathname: `/reports/district/leaderboards`,
        query: {
          ...queryParams,
          view: `classes`
        }
      }
    }, {
      key: 'students',
      title: 'Students',
      icon: false,
      link: {
        pathname: `/reports/district/leaderboards`,
        query: {
          ...queryParams,
          view: `students`
        }
      }
    }]
  }, {
    key: 'proficiency',
    title: 'Proficiency',
    icon: false,
    link: {
      pathname: `/reports/district/proficiency`,
      query: queryParams
    }
  }, {
    key: 'growth',
    title: 'Growth',
    icon: false,
    link: {
      pathname: `/reports/district/growth`,
      query: queryParams
    }
  }, uiContext.entity.isMic && {
    key: 'community-users',
    title: 'Community Users',
    icon: true,
    children: [{
      key: 'community',
      title: 'Community Summary',
      icon: false,
      link: {
        pathname: `/reports/district/community`,
        query: queryParams
      }
    }, {
      key: 'community-details',
      title: 'Community Details',
      icon: false,
      link: {
        pathname: `/reports/district/community-details`,
        query: queryParams
      }
    },,]
  }].filter(e => e);
  const title = <>
      District: {uiContext.entity.name}, {uiContext.entity.state.abbreviation}{' '}
      {uiContext.entity.isMic && <Tag color="green">MIC</Tag>}
    </>;
  return {
    menu,
    title
  };
};
export default DistrictInfoHeader;