import { useCallback, useState } from 'react';
const useLocalStorageState = (key, initValue) => {
  const [value, setter] = useState(() => {
    const item = window.localStorage.getItem(key);
    return item == null ? initValue : JSON.parse(item);
  });
  const storageSetter = useCallback(newVal => {
    window.localStorage.setItem(key, JSON.stringify(newVal));
    return setter(newVal);
  }, [key, setter]);
  return [value, storageSetter];
};
export default useLocalStorageState;