import { createElement } from 'react';
import ComponentBlocksBanner from './Banner';
import { MenuLink as ComponentMenuMenuLink, MenuDropdown as ComponentMenuDropDownMenu } from './Menu';
import ComponentBlocksFeaturedGrid from './FeaturedGrid';
import ComponentInputSearchInput from './Search';
const classes = {
  ComponentBlocksFeaturedGrid,
  ComponentBlocksBanner,
  ComponentInputSearchInput,
  ComponentMenuMenuLink,
  ComponentMenuDropDownMenu
};
const createComponent = ({
  __typename,
  ...item
}, index) => createElement(classes[__typename], item);
export default createComponent;