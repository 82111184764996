import { Spin } from 'antd';
import gql from 'graphql-tag';
import { get } from 'lodash';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import rules from '../access_rights';
import UIButtonLink from './ui/ButtonLink';
import { useQuery, useSession } from '../lib/hooks';
import ErrorMessageForbidden from '../pages/403';
const ruleFromHref = ({
  pathname,
  query
}) => {
  const rule = new String(`page:${pathname.split('/').filter(Boolean).join('/')}`);
  rule.args = [query];
  return rule;
};
const queryFromRule = rule => {
  const {
    user
  } = useSession();
  const [action, object] = rule.split(':');
  const field = rules[action][object] && rules[action][object](user, ...(rule.args ?? [])) || false;
  return ['query', `ACCESS_CONTROL__${action}__${object.replace(/[^A-Za-z0-9_]/g, '_')}`, field];
};
const extractValue = data => typeof data !== 'object' ? !!data : Object.entries(data).some(([k, v]) => k !== '__typename' && extractValue(v));
const CheckAccess = ({
  rule,
  children,
  ifCant = null,
  onChecked,
  loadType
}) => {
  const [type, name, field] = queryFromRule(rule);
  const {
    error,
    loading,
    data
  } = useQuery(gql`${type} ${name}{ ${field} }`, {
    skip: typeof field === 'boolean',
    fetchPolicy: 'no-cache'
  });
  const can_access = typeof field === 'boolean' ? field : extractValue(data);
  useEffect(() => {
    !loading && onChecked && onChecked(can_access);
  });
  return loading ? loadType || <Spin /> : can_access ? children : ifCant;
};
const LinkLabel = ({
  href,
  label
}) => {
  const route = useRouter();
  const cls = ['pathname', 'query.slug', 'query.id'].every(k => get(route, k) === get(href, k)) ? "current" : "";
  return <NextLink href={href} className={cls} children={label} data-sentry-element="NextLink" data-sentry-component="LinkLabel" data-sentry-source-file="AccessControl.jsx" />;
};
const Link = ({
  href,
  rule,
  label,
  ...rest
}) => <CheckAccess {...rest} rule={rule ?? ruleFromHref(href)} children={<LinkLabel href={href} label={label} />} data-sentry-element="CheckAccess" data-sentry-component="Link" data-sentry-source-file="AccessControl.jsx" />;
const ButtonLink = ({
  href,
  rule,
  label,
  ifCant,
  onChecked,
  loadType,
  ...rest
}) => <CheckAccess ifCant={ifCant} onChecked={onChecked} loadType={loadType} rule={rule ?? ruleFromHref(href)} children={<UIButtonLink url={href} children={label} {...rest} />} data-sentry-element="CheckAccess" data-sentry-component="ButtonLink" data-sentry-source-file="AccessControl.jsx" />;
const Page = ({
  children
}) => <CheckAccess rule={ruleFromHref(useRouter())} ifCant={<ErrorMessageForbidden />} children={children} data-sentry-element="CheckAccess" data-sentry-component="Page" data-sentry-source-file="AccessControl.jsx" />;
export default {
  CheckAccess,
  Link,
  ButtonLink,
  Page,
  ruleFromHref
};