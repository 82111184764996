import { Menu } from 'antd';
import { FolderOutlined, LineChartOutlined } from '@ant-design/icons';
import { sortBy, uniq } from 'lodash';
import ACLinksMenu from './ACLinksMenu';
import breadcrumbFor from './breadcrumbFor';
import ErrorMessage from '../../../components/ErrorMessage';
import { LoadingMenu } from '../../../components/ui/LoadingCards';
import { UI_DISTRICT_QUERY } from '../../../helpers/gqlQueries/ui/tree';
import { useQuery } from '../../../lib/hooks';
export default function DistrictMenu({
  districtId,
  semester,
  updateState,
  hideReports,
  setIsOpen
}) {
  const {
    loading,
    error,
    data
  } = useQuery(UI_DISTRICT_QUERY, {
    variables: {
      districtId,
      semester
    }
  });
  if (loading) {
    return <LoadingMenu />;
  }
  if (error) {
    return <ErrorMessage message="Error loading data." />;
  }
  const links = [{
    href: {
      pathname: '/reports/district/overview',
      query: {
        id: districtId
      }
    },
    label: <span className="data-menu-link">
          <LineChartOutlined /> District Dashboard
        </span>
  }];
  const schools = sortBy(data.districtById.schools || [], s => s.name.toLowerCase());
  const {
    DISTRICT: districtSchools,
    PARTNER: partnerSchools
  } = Object.fromEntries(uniq(schools.map(s => s.type)).map(t => [t, schools.filter(s => s.type === t)]));
  return <>
      <ACLinksMenu onClick={() => setIsOpen(false)} title="District" links={links} data-sentry-element="ACLinksMenu" data-sentry-source-file="DistrictMenu.jsx" />
      {schools.length > 0 && <Menu onClick={e => updateState(breadcrumbFor(schools.find(s => s.id === e.key)))} items={[{
      type: 'group',
      key: 'district-schools',
      label: 'District Schools',
      children: districtSchools?.map(school => ({
        key: school.id,
        label: <>
                      <FolderOutlined /> {school.name}
                    </>
      }))
    }, {
      type: 'group',
      key: 'partner-schools',
      label: 'Partner Schools',
      children: partnerSchools?.map(school => ({
        key: school.id,
        label: <>
                      <FolderOutlined /> {school.name}
                    </>
      }))
    }].filter(g => g.children?.length > 0)} />}
    </>;
}